import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../styles/Carousel.css'

const Carousel = ({ slides}) => {
  const settings = {
   
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };

  return (
 
    <Slider {...settings}>
    {slides.map((slide, index) => (
      <div key={index} className="carousel-slide">
        <img src={slide.image} alt={slide.title} />
        <div className="slide-content">
          <h2>{slide.title}</h2>
          <p>{slide.description}</p>
          {/* <button>{slide.button} </button> */}
          
          
       
        
        
        </div>
      </div>
    ))}
  </Slider>
  );
};

export default Carousel;
