import React from 'react';

import TravelPackageCard from './TravelPackageCard';
import '../styles/TravelPackages.css'
import { Link } from 'react-router-dom';





const Hiking = () => {
  const packages = [
    {
      title: 'Kuari Pass Trek',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      price: 10000,
      imageUrl: 'https://images.unsplash.com/photo-1649999132739-52f8da421441?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80',
      button:  <Link to="/kuari-pass">
      <button className="card-button">Book Now</button>
    </Link>
    
    },
   
    {
      title: 'Satopanth Trek',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      price: 12000,
      imageUrl: 'https://images.unsplash.com/photo-1545652985-5edd365b12eb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80',
      button:  <Link to="/satopanth-trek">
      <button className="card-button">Book Now</button>
    </Link>
    
    },

    {
      title: 'Bagni Glacier Trek',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      price: 11000,
      imageUrl: 'https://images.unsplash.com/photo-1596953866065-0cffbc735059?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80',
      button:  <Link to="/bagni-glacier-trek">
      <button className="card-button">Book Now</button>
    </Link>
    },

    {
      title: 'Pangarkula Trek',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      price: 8000,
      imageUrl: 'https://images.unsplash.com/photo-1611154046036-cd91e50978be?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80',
      button:  <Link to="/pangarchula-Trek">
      <button className="card-button">Book Now</button>
    </Link>
   
    },
    


  ];

  return (
    <div >
    <h2 className='packages-heading'>Hiking</h2>
      <div className="package-cards packages-container">
        {packages.map((packages) => (
          <TravelPackageCard
            key={packages.title}
            title={packages.title}
            description={packages.description}
            price={packages.price}
            imageUrl={packages.imageUrl}
            button={packages.button}
          />
        ))}
      </div>
    </div>
  );
};

export default Hiking;
