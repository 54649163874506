import React from 'react'
import '../styles/SpiritualTour.css'
import ActivitiesCard from './ActivitiesCard';


import { Link } from 'react-router-dom';

const RoadTrips = () => {
  
     const packages = [
    {
      title: 'Kumaon Expeds',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    
      imageUrl: 'https://images.unsplash.com/photo-1567939696026-529d1b096303?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80',
      button:  <Link to="/">
      <button className="card-button">Book Now</button>
    </Link>
     
    
   
    
    },
    {
      title: 'Garwhal Expeds',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    
      imageUrl: 'https://images.unsplash.com/photo-1510596713412-56030de252c8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80',
      button:  <Link to="/">
      <button className="card-button">Book Now</button>
    </Link>
    
    },
    
  ]
  return (
    <>
    <div >
    <h2 className='packages-heading'>Road Trips</h2>
      <div className="package-cards packages-container">
        {packages.map((packages) => (
          <ActivitiesCard
            key={packages.title}
            title={packages.title}
           imageUrl={packages.imageUrl}
            button={packages.button}
          />
        ))}
      </div>
    </div>

    
    </>
  )
}

export default  RoadTrips 
