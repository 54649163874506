import React from 'react'
import '../styles/SpiritualTour.css'
import ActivitiesCard from './ActivitiesCard';


import { Link } from 'react-router-dom';

const OffbeatTrek = () => {
  
     const packages = [
    {
      title: 'Rishikund Trek',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    
      imageUrl: 'https://images.unsplash.com/photo-1669205784567-058747ddf64d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=764&q=80',
      button:  <Link to="/">
      <button className="card-button">Book Now</button>
    </Link>
     
    
   
    
    },
    {
      title: 'Kakbhusandi Trek',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    
      imageUrl: 'https://images.unsplash.com/photo-1674630583722-204cb7075134?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80',
      button:  <Link to="/">
      <button className="card-button">Book Now</button>
    </Link>
    
    },
    {
      title: 'Ghiyavinayak Trek',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    
      imageUrl: 'https://images.unsplash.com/photo-1682685794761-c8e7b2347702?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80',
      button:  <Link to="/">
      <button className="card-button">Book Now</button>
    </Link>
    
    }
  ]
  return (
    <>
    <div >
    <h2 className='packages-heading'>Off Beat Trek</h2>
      <div className="package-cards packages-container">
        {packages.map((packages) => (
          <ActivitiesCard
            key={packages.title}
            title={packages.title}
           imageUrl={packages.imageUrl}
            button={packages.button}
          />
        ))}
      </div>
    </div>

    
    </>
  )
}

export default OffbeatTrek