import React from "react";
import BlogComponent from "./BlogComponent";

const BlogAdiKailash = () => {
  const sampleBlog = {
    title: `Adi Kailash Yatra: An Ethereal Journey to the Hidden Realm of Uttarakhand`,
    content: (
      <p>
        Adi Kailash is a sacred mountain located in the Garhwal Himalayas of
        Uttarakhand, India. It is believed to be the abode of Lord Shiva and is
        one of the most important pilgrimage sites for Hindus. The mountain is
        also known as Mount Kailash, Kangrinboqe, or Tise. The Adi Kailash Yatra
        is a challenging but rewarding journey that takes pilgrims through some
        of the most beautiful and remote regions of Uttarakhand. The route to
        the mountain passes through forests, glaciers, and high passes. Pilgrims
        must be in good physical condition to complete the journey.
        <br/> The Adi
        Kailash Yatra begins in the town of Joshimath. From Joshimath, pilgrims
        travel to the village of Govindghat, where they begin the ascent to the
        mountain. The first few days of the journey are relatively easy, as the
        trail winds through forests and meadows. However, the trail becomes more
        challenging as it climbs higher into the mountains. Pilgrims must cross
        several high passes on the way to Adi Kailash, including the Burfu La
        Pass (15,200 feet) and the Lipu Lekh Pass (17,060 feet). <br/>
        The Burfu La
        Pass is the highest point on the route and is known for its stunning
        views of the surrounding mountains. The Lipu Lekh Pass is the border
        between India and Nepal. After crossing the Lipu Lekh Pass, pilgrims
        descend into the Kali Gandaki Valley. The Kali Gandaki Valley is one of
        the deepest valleys in the world and is home to some of the most
        spectacular scenery in the Himalayas. Pilgrims follow the Kali Gandaki
        River to the village of Dharchula, where they begin the final ascent to
        Adi Kailash. The final ascent to Adi Kailash is steep and challenging.
        Pilgrims must climb over 10,000 feet in elevation in just a few days.<br/>
        The trail is rocky and slippery, and the weather can be unpredictable.
        However, the views from the top of Adi Kailash are simply breathtaking.
        Pilgrims who reach the summit of Adi Kailash are said to be granted a
        boon by Lord Shiva. The mountain is a place of peace and tranquility,
        and it is said that all negative thoughts and emotions are washed away
        at its feet. The Adi Kailash Yatra is a once-in-a-lifetime experience
        that is sure to leave a lasting impression on any pilgrim who undertakes
        it. It is a journey of physical and spiritual challenge, but it is also
        a journey of great beauty and reward.<br/> Here are some tips for planning
        your Adi Kailash Yatra: Start planning your trip well in advance. The
        Adi Kailash Yatra is a popular pilgrimage and it is important to book
        your accommodations and permits early. Be in good physical condition.
        The journey is challenging and you will need to be able to hike for
        several hours a day at high altitudes. Hire a guide. A guide can help
        you plan your itinerary, navigate the trails, and carry your luggage. Be
        prepared for the weather. The weather in the Himalayas can change
        quickly, so be prepared for anything. Pack light. You will only need the
        essentials for the journey. Bring sunscreen, sunglasses, and a hat. The
        sun can be very strong at high altitudes. Bring a first-aid kit. You may
        need it in case of an emergency. Be respectful of the local culture. The
        Adi Kailash Yatra is a sacred pilgrimage for Hindus. Be respectful of
        the mountain and the people who live in the region. I hope this blog
        post has inspired you to undertake the Adi Kailash Yatra. It is a truly
        amazing journey that is sure to change your life.
      </p>
    ),
    date: "September 20, 2022",
    imageUrl:
      "https://e1.pxfuel.com/desktop-wallpaper/738/356/desktop-wallpaper-kailash-om-parvat.jpg",
  };

  return (
    <div className="blog-page">
      <BlogComponent
        title={sampleBlog.title}
        content={sampleBlog.content}
        date={sampleBlog.date}
        imageUrl={sampleBlog.imageUrl}
      />
    </div>
  );
};

export default BlogAdiKailash;
