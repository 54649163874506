import React from 'react'
import '../styles/Contact.css'

const Contact = () => {
  return (
    <div className="contact-us-container">
    <div className="contact-us-header">
      <h1>Contact Us</h1>
      <p>Have questions or feedback? Get in touch with us!</p>
    </div>
    <div className="contact-us-form">
      <form>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input type="text" id="name" name="name" required />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input type="email" id="email" name="email" required />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea id="message" name="message" rows="4" required></textarea>
        </div>
        <button type="submit">Send Message</button>
      </form>
    </div>
    <div className="contact-us-info">
      <h2>Contact Information</h2>
      <p>Email: ajaymountainsoul@gmail.com</p>
      <p>Phone: +91 80064 04570 </p>
      <p>Address: Dehradun ,Uttrakhand, India</p>
    </div>
  </div>
  )
}

export default Contact