import React, { useState } from 'react';
import '../styles/TripPlanner.css';

const TripPlanner = ({ sections }) => {
  const [activeTab, setActiveTab] = useState(sections[0].id);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="trip-planner">
      <div className="tab-buttons">
        {sections.map((section) => (
          <button
            key={section.id}
            className={activeTab === section.id ? 'active' : ''}
            onClick={() => handleTabChange(section.id)}
          >
            {section.title}
          </button>
        ))}
      </div>
      <div className="content">
        {sections.map((section) =>
          activeTab === section.id && (
            <div className="section-content" key={section.id}>
              <h2>{section.title}</h2>
              {section.content}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default TripPlanner;
