import React from 'react'
import ActivitiesCard from './ActivitiesCard';
import { Link } from 'react-router-dom';
import '../styles/Activities.css'

const Activities = () => {

  const packages = [
    {
      title: 'Spiritual Tour',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    
      imageUrl: 'https://images.unsplash.com/photo-1612438214708-f428a707dd4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80',
      button:  <Link to="/spiritual-tour">
      <button className="card-button">View Details</button>
    </Link>
   
    
    },
    {
      title: 'Hiking',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    
      imageUrl: 'https://images.unsplash.com/uploads/141148589884100082977/a816dbd7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80',
      button:  <Link to="/hiking">
      <button className="card-button">View Details</button>
    </Link>
    
    },
    {
      title: 'Off-Beat Trek',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    
      imageUrl: 'https://images.unsplash.com/photo-1682687220640-9d3b11ca30e5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80',
      button:  <Link to="/offbeat-trek">
      <button className="card-button">View Details</button>
    </Link>
    
    },
    {
      title: 'Road Trips',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    
      imageUrl: 'https://images.unsplash.com/photo-1572401611152-cf63d874b019?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80',
      button:  <Link to="/road-trips">
      <button className="card-button">View Details</button>
    </Link>
   
    
    }
  ]

  return (
    <>
<div >
    <h2 className='packages-heading'>Activities</h2>
      <div className="package-cards packages-container">
        {packages.map((packages) => (
          <ActivitiesCard
            key={packages.title}
            title={packages.title}
           imageUrl={packages.imageUrl}
            button={packages.button}
          />
        ))}
      </div>
    </div>



    </>
  )
}

export default Activities