import React from 'react';
import '../styles/Services.css'

import { BiCalendar } from 'react-icons/bi';
import { FaHeadset, FaHotel } from 'react-icons/fa';
import { IoMdBus } from 'react-icons/io';
import { FaSuitcaseRolling, FaUtensils,FaUser } from 'react-icons/fa';
import { BsFillStarFill } from 'react-icons/bs';
import {FaShieldAlt, FaSuitcase } from 'react-icons/fa';

function Services() {
  return (
    <section className="services-section">
      <h2 className="section-title">Discover the Soulful Trails: Unite Hiking and Spirituality with Our Divine Travel Services!</h2>
      <div className="services-container">
        <div className="service-card">
          <BiCalendar size={40} />
          <h3 className="service-title">Planned itineraries</h3>
          <p className="service-description">
            Well-crafted journeys with perfect arrangements, creating unforgettable memories.
          </p>
        </div>
        <div className="service-card">
          <FaHeadset size={40} />
          <h3 className="service-title">Customer service</h3>
          <p className="service-description">
            Responsive, helpful, and attentive, ensuring customer satisfaction and loyalty.
          </p>
        </div>
        <div className="service-card">
          <IoMdBus size={40} />
          <h3 className="service-title">Ground transportation</h3>
          <p className="service-description">
            Reliable, efficient, and comfortable, ensuring seamless travel experiences.
          </p>
        </div>

        <div className="service-card">
          <FaSuitcaseRolling size={40} />
          <h3 className="service-title">Variety of Travel Planning</h3>
          <p className="service-description">
            Diverse and personalized, catering to unique travel preferences and interests.
          </p>
        </div>

        <div className="service-card">
          <BsFillStarFill size={40} />
          <h3 className="service-title">Premium Packages</h3>
          <p className="service-description">
            Exclusive, luxurious, and tailored for exceptional travel experiences.
          </p>
        </div>
        <div className="service-card">
          <FaUtensils size={40} />
          <h3 className="service-title">All meals</h3>
          <p className="service-description">
            Delectable, nutritious, and thoughtfully prepared, offering delightful culinary experiences.
          </p>
        </div>
        <div className="service-card">
          <FaHotel size={40} />
          <h3 className="service-title">Accommodation</h3>
          <p className="service-description">
            Comfortable, cozy, and well-appointed, providing a home away from home.
          </p>
        </div>
        <div className="service-card">
          <FaSuitcase size={40} />
          <h3 className="service-title">Tour managers</h3>
          <p className="service-description">
            Seasoned, knowledgeable, and attentive, guiding memorable journeys with expertise.
          </p>
        </div>

        <div className="service-card">
          <FaShieldAlt size={40} />
          <h3 className="service-title">Trusted Sites</h3>
          <p className="service-description">
          Verified, reliable sites for safe and adventurous trekking experiences.
          </p>
        </div>
        <div className="service-card">
          <FaUser size={40} />
          <h3 className="service-title">Personalization</h3>
          <p className="service-description">
          Tailor made  travel itineraries to suit individual interests and requirements.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Services;
