import React from "react";
import Carousel from "./Carousel";
import SlideBarCard from "./SlideBarCard";
import TripPlanner from "./TripPlanner";


import { MdTent } from 'react-icons/md';

import "../styles/AllTrek.css";

const SatopanthTrek = () => {
  const slides = [
    {
      id: 1,
      image: 'https://images.unsplash.com/photo-1526772662000-3f88f10405ff?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80',
    
    },
    {
      id: 2,
      image: 'https://images.unsplash.com/photo-1520208422220-d12a3c588e6c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80',
      
    },
    {
      id: 3,
      image: 'https://images.unsplash.com/photo-1519098635131-4c8f806d1e82?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80',
     
    },
  ];

  const slidebar = [
    {
      title: "Accomodation",
      para: "Hotel,Camps",
      imageUrl:'https://cdn-icons-png.flaticon.com/128/489/489870.png'
    },

    {
      title: "Best Season",
      para: "Round The Year",
      imageUrl: "https://cdn-icons-png.flaticon.com/128/4360/4360322.png",
    },
    

    {
      title: "Meals",
      para: "Breakfast,Lunch,Dinner",
      imageUrl: "https://cdn-icons-png.flaticon.com/128/857/857681.png",
    },

    {
      title: "Activities",
      para: "Hiking,Camping",
      imageUrl: "https://cdn-icons-png.flaticon.com/128/2280/2280969.png",
    },





  ];

  const sections = [
    {
      id: "overview",
      title: "Overview",
      content: (
        <p>
          Location – Upper Alkananda Valley Garhwal Himalayas Level – Moderate <br/>
          Highest point – Satopanth Lake (14,271 ft./ 4350 m)  <br/>Tour Duration:
          8N/9D Duration – May – June – July & September – October <br/> Destinations
          covered – Haridwar – Rishikesh – Joshimath – Badrinath – Satopanth
          Lake
        </p>
      ),
    },
    {
      id: "short-itinerary",
      title: "Short Itinerary",
      content: (
        <p>
          Day 1 : Delhi – Dehradun airport / Haridwar hotel. <br/>Day 2 : Haridwar /
          Rishikesh – Joshimath (6,151 ft. /1874m) 270 km, 8 hrs, hotel.<br/>Day 3 :
          Joshimath (6,151 ft. /1874m) – Badrinath (10170 ft. / 3100 m) – Laxmi
          Van (11,975 ft. / 3,650 m) drive 45 km, 2 hour & trek 6 km, 4-5 hour
          camp. <br/>Day 4 : Laxmivan (11,975 ft. / 3,650 m) – Chakrateeth (13,681
          ft. / 4170m) trek 7 km, 4 hour camp.<br/> Day 5 : Chakrateerth (13,681 ft.
          / 4170m) - Satopanth (14,271 ft. / 4350 m) – Chakrateeth(13,681 ft./
          4170m) 7km both way 6 -7 hour camp.<br/> Day 6 : Chakrateerth (13,681 ft./
          4170m) – Laxmivan (11,975 ft. / 3,650 m) trek 5 hour camp. <br/>Day 7 :
          Laxmi Van (11,975 ft. / 3,650 m) – Mana (10,500 ft. /3200 m) –
          Badrianth (10,170 ft. /3100m) trek 4 -5 hours and drive 3 km hotel.<br/>
          Day 8 : Badrinath – Haridwar / Dehradun 323 km, 9 hour hotel.<br/> Day 9 :
          DEPARTURE.
        </p>
      ),
    },
    {
      id: "detailed-itinerary",
      title: "Detailed Itinerary",
      content: (
        <ul>
          <li>Day 1 : Delhi – Dehradun airport / Haridwar hotel.</li>
          <p>
            Landing at Jolly Grant airport Dehradun / Haridwar Train Station and
            transfer to hotel. Day at leisure or local sightseeing depends on
            what hour of the day you reach here. Rishikesh and Haridwar set by
            the revered Ganges are the towns of prime centers of the Hindu
            religious and spiritual importance.
          </p><br/>

          <li>
            Day 2 : Haridwar / Rishikesh – Joshimath (6,151 ft. /1874m) 270 km,
            8 hrs, hotel.
          </li>
          <p>
            Morning drive heading towards Joshimath, along with the beautifully
            carved valleys of The Ganges and Alkananda rivers, we make short
            stops on the way for breakfast, lunch and photography. On the way
            meet 4 sacred Prayags (confluences). Arrive at Joshimath check in at
            hotel. Evening take short tour to explore the town, spiritually
            described as Jyotri math, which is gifted with grace of Adi Guru
            Shankrachaya, where he attained enlightenment after a prolonged
            penance under a natural rock shelter.
          </p><br/>

          <li>
            Day 3 : Joshimath (6,151 ft. /1874m) – Badrinath (10170 ft. / 3100
            m) – Laxmi Van (11,975 ft. / 3,650 m) drive 45 km, 2 hour & trek 6
            km, 4-5 hour camp.
          </li>
          <p>
            Morning after breakfast, drive along the Alkananda river, on the way
            you have an opportunity to see the last and 5th Prayag ( holy
            confluence ) at Vishnu Prayag, where the Dhauli Ganaga merges with
            the Alkananda and gushes all the way down until Dev Prayag to meet
            the Bagirathi and forms the Ganga. On the way you pass through small
            but popular settlements of Govind Ghat and Pandukeshwar, where from
            a trek route deviates for the Valley of Flowers and Hem Kund Sahib,
            the highest sikh shrine in the world. Reaching Mana village via
            Badrianth, trek starts with packed lunch, passes through Mana
            village, crossing over the sacred Sarswati River and catch the
            stunning view of Vashudhara falls and Laxmivan.
          </p><br/>

          <li>
            Day 4 : Laxmivan (11,975 ft. / 3,650 m) – Chakrateeth (13,681 ft. /
            4170m) trek 7 km, 4 hour camp.
          </li>
          <p>
            After breakfast, we trek with packed lunch, on a cobbled trail takes
            through undulating terrain that runs over a couple of ascent and
            descent, mostly stark and harsh terrain, but full of scenic beauty
            with the mesmerizing view of Satopanth massif on its backdrop.
            Arriving at camp enjoy your hot cup to tea / coffee with snacks,
            later savor your dinner and go to bed.
          </p><br/>
          <li>
            Day 5 : Chakrateerth (13,681 ft. / 4170m) - Satopanth (14,271 ft. /
            4350 m) – Chakrateeth(13,681 ft./ 4170m) 7km both way 6 -7 hour
            camp.
          </li>

          <p>
            Today’s walk is going to be a hard one, starts with an hour’s steep
            climb on top of a ridge, is undoubtedly an amazing spot for viewing
            Satopanth Lake with a spectacular rim of Satopanth and Swarga Rohini
            flanked by Parvati and Balkun peaks. A descent of next one and half
            walk on a bolder strewn path with take you to Satopanth Lake.
            Reaching at the lake, relax and catch your breath and enjoy looking
            at vast sprawling Swarga Rohini Glacier that feeds the lake. If time
            and your body allow, you can go further towards Suraj kund and
            Chandra Kund. Later Back to camp at Chakrateeth.
          </p><br/>

          <li>
            Day 6 : Chakrateerth (13,681 ft./ 4170m) – Laxmivan (11,975 ft. /
            3,650 m) trek 5 hour camp.
          </li>
          <p>
            Today’s trek all the way back on the same trail, it’s going to be
            relatively easier, while you are descending down to the camp.
            Reaching at camp enjoy tea and coffee and relax by basking in the
            sun or share a story with your fellow trekkers.
          </p><br/>

          <li>
            Day 7 : Laxmi Van (11,975 ft. / 3,650 m) – Mana (10,500 ft. /3200 m)
            – Badrianth (10,170 ft. /3100m) trek 4 -5 hours and drive 3 km
            hotel.
          </li>
          <p>
            After breakfast, you follow the same trail to Badrinath, take lunch
            on the way, arrival at Badrinath check into hotel, take tea/ coffee,
            freshen up and evening take a holy dip in the hot sulfuric water and
            treat your body against allergic ailments and participate in the
            sacred Aarati (worship), visit the grandeur of Badrinath temple, be
            blessed with the holy abode of lord Vishnu one of the four major
            Dhams (pilgrim) all over the country.
          </p><br/>

          <li>Day 8 : Badrinath – Haridwar / Dehradun 323 km, 9 hour hotel.</li>
          <p>
            Take an early wake up to check out and drive same way down into the
            Alkananda Valley. Make short stops for meals and photographs on the
            way. Reaching Haridwar / Dehradun, you either take an overnight stay
            or continue your journey towards home destination.
          </p><br/>

          <li>Day 9 : DEPARTURE.</li>

          <p>
            Morning after breakfast checkout from hotel and head to your desired
            destination with the happy memories of trek.
          </p>
        </ul>
      ),
    },
  ];

  return (
    <>

      <Carousel slides={slides} />

      <div className="trek-details">
        <div className="trek-into">
          <h2>Satopanth Trek</h2>
          <p>
            Satopanth Lake, 26 km from Badrinath, sits in foot of Mount
            Swargarohini at an altitude of 4350m. It is a triangular lake with
            serene glacial water has a periphery of about half of kilometer. It
            is believed that Brahma, Vishnu and Mahesh the Hindu trinity occupy
            one at each corner and are named after them. The trek itself is a
            pilgrim cum adventure journey passes through, the gushing Sarswati
            river close by Mana Village and some wonderfully sparse lush meadows
            embedded with exquisite floral patches at Chamtoli and Laxmivan. A
            charismatic array of waterfalls at Sahstra Dhara, sturdily erected
            huge rock pillars and boulders that all together inspire you to
            discover your inner self and eventually connect you with his
            supremacy. Further the track is hazardous, strewn by a couple
            patches of bolder and loose dead glacier terrains but full of
            dramatic scenery that offer a true blend of adventure and pilgrim.
            Chaukhamha, Neelkath, Parvati, Sattopanth cole, Balkun, Narayan,
            Molpha bank can be viewed closely. According to the Hindu mythology,
            Lord Dharam Raj (God of truth) himself descended from heaven to
            receive Pandavas , in the form of a diseased black dog and here on
            this mystic journey According to our ancient scriptures, the
            Pandavas abandoned their bodies and attained salvation to their
            eternal holy home, the heaven. Yudhishthar, was one who went to
            heaven alive and received by Dharam Raja rode upon a divine chariot.
            In addition to reverence of Satopanth Lake and its divine mysterious
            facts, it’s a land where our holy epic of the Maha Bharata was
            written under rock shelter by an ascetic saint Vyas ji shelter in
            Mana Village. This charismatic land is connected with many legends
            and lore, Lord Vishnu himself fascinated by overspreading enchanting
            beauty of this place and chose it as his own home in the Himalaya.
          </p>
          <div>
            <TripPlanner sections={sections} />
          </div>
        </div>

        <div className="trek-sidebar">
          {slidebar.map((slidebar) => (
            <SlideBarCard
              key={slidebar.title}
              title={slidebar.title}
              para={slidebar.para}
              imageUrl={slidebar.imageUrl}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default SatopanthTrek;
