import React from 'react';
import '../styles/BlogComponent.css';

const BlogComponent = (props) => {
  const { title, content, date, imageUrl } = props;

  return (
    <div className="blog-component-card">
      {imageUrl && <img className="blog-component-image" src={imageUrl} alt="Blog" />}
      <h2 className="blog-component-title">{title}</h2>
      <p className="blog-component-date">{date}</p>
      <div className="blog-component-content">{content}</div>
    </div>
  );
};

export default BlogComponent;
