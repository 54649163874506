import React from "react";
import BlogComponent from "./BlogComponent";

const BlogHiking = () => {
  const sampleBlog = {
    title: `Hiking in Uttarakhand: A Guide to the Best Treks in the Land of the Gods`,
    content: (
      <p>
 
        Uttarakhand is a state in northern India that is home to some of the
        most beautiful and challenging trekking trails in the world. The state
        is known for its stunning Himalayan scenery, its rich history and
        culture, and its friendly and welcoming people. There are many different
        hiking trails to choose from in Uttarakhand, ranging from easy day hikes
        to challenging multi-day treks. Some of the most popular treks in
        Uttarakhand include:
        <br/> Ghangora Valley Trek: This is a beautiful trek that
        takes you through the lush forests and meadows of the Garhwal Himalayas.
        The trek culminates in the stunning Ghangora Valley, which is home to
        the Kedarnath Temple, one of the most important Hindu pilgrimage sites.
     <br/>
        Ghangora Valley Trek in Uttarakhand Valley of Flowers Trek: This is a
        challenging but rewarding trek that takes you through a valley of alpine
        meadows and wildflowers. The trek is home to a variety of rare and
        endangered plants, and it is a popular destination for birdwatchers.
       <br/>
        Valley of Flowers Trek in Uttarakhand Roopkund
        Trek: This is a mysterious trek that takes you to a lake where the
        skeletons of hundreds of people have been found. The lake is said to be
        cursed, and it is a popular destination for those who are looking for a
        unique and challenging trek.
        <br/> Roopkund Trek in Uttarakhand Har Ki Doon
        Trek: This is a popular trek that takes you through the scenic Har Ki
        Doon Valley. The trek is relatively easy, and it is a great way to
        experience the beauty of the Garhwal Himalayas.
        <br/>Pindari Glacier Trek: This is a challenging trek that
        takes you to the Pindari Glacier, one of the largest glaciers in the
        Garhwal Himalayas. The trek is a great way to experience the power and
        beauty of the Himalayas.
        <br/>  Pindari Glacier Trek in Uttarakhand No
        matter what your level of experience or fitness, there is a hiking trail
        in Uttarakhand that is perfect for you. So what are you waiting for?
        Start planning your trip today! Here are some tips for planning your
        hiking trip to Uttarakhand:
        <br/> Start planning your trip well in advance.
        The best time to hike in Uttarakhand is during the spring (March-May)
        and fall (September-November). However, the trails can be crowded during
        these times, so it is important to book your accommodations and permits
        early. Be in good physical condition. Hiking in Uttarakhand can be
        challenging, so it is important to be in good physical condition before
        you start your trek. 
        <br/>Hire a guide. A guide can help you plan your
        itinerary, navigate the trails, and carry your luggage. Be prepared for
        the weather. The weather in the Himalayas can change quickly, so it is
        important to be prepared for anything. Pack layers of clothing so that
        you can adjust to the changing temperatures. Pack light. You will only
        need the essentials for your hike. Bring a backpack that is no more than
        20-25 liters in size. Bring sunscreen, sunglasses, and a hat. The sun
        can be very strong at high altitudes. Bring a first-aid kit. You may
        need it in case of an emergency. Be respectful of the local culture.
        Uttarakhand is a sacred place for Hindus, so be respectful of the local
        culture and traditions. I hope this blog post has inspired you to go
        hiking in Uttarakhand. It is a truly amazing place to hike, and you are
        sure to have an unforgettable experience.
      </p>
    ),
    date: "December 20, 2022",
    imageUrl:
      "https://images.pexels.com/photos/7846473/pexels-photo-7846473.jpeg?auto=compress&cs=tinysrgb&w=600",
  };

  return (
    <div className="blog-page">
      <BlogComponent
        title={sampleBlog.title}
        content={sampleBlog.content}
        date={sampleBlog.date}
        imageUrl={sampleBlog.imageUrl}
      />
    </div>
  );
};

export default BlogHiking;
