import React from "react";
import Carousel from "./Carousel";
import SlideBarCard from "./SlideBarCard";
import TripPlanner from "./TripPlanner";

import { MdTent } from "react-icons/md";

import "../styles/AllTrek.css";

const PanchKedarYatra = () => {
  const slides = [
    {
      id: 1,
      image:
        "https://images.unsplash.com/photo-1612438214708-f428a707dd4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80",
    },
    {
      id: 2,
      image:
        "https://images.unsplash.com/photo-1571328565610-56f07b8bf3ce?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=735&q=80",
    },
    {
      id: 3,
      image:
        "https://images.unsplash.com/photo-1676182259623-a5f4c8d0a8a4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1168&q=80",
    },
  ];

  const slidebar = [
    {
      title: "Accomodation",
      para: "Hotel,Camps",
      imageUrl: "https://cdn-icons-png.flaticon.com/128/489/489870.png",
    },

    {
      title: "Best Season",
      para: "Round The Year",
      imageUrl: "https://cdn-icons-png.flaticon.com/128/4360/4360322.png",
    },

    {
      title: "Meals",
      para: "Breakfast,Lunch,Dinner",
      imageUrl: "https://cdn-icons-png.flaticon.com/128/857/857681.png",
    },

    {
      title: "Activities",
      para: "Hiking,Camping",
      imageUrl: "https://cdn-icons-png.flaticon.com/128/2280/2280969.png",
    },
  ];

  const sections = [
    {
      id: "overview",
      title: "Overview",
      content: (
        <p>
          The Panch Kedar Yatra is a revered pilgrimage journey in Uttarakhand,
          India, focusing on five ancient temples dedicated to Lord Shiva. The
          temples included are Kedarnath, Madhyamaheshwar, Tungnath, Rudranath,
          and Kalpeshwar. The yatra begins with the famous Kedarnath Temple,
          located at a height of 3,583 meters, and continues to the remote and
          picturesque temples of Madhyamaheshwar, Tungnath, Rudranath, and
          Kalpeshwar. Pilgrims undertake treks through stunning landscapes,
          dense forests, and meadows to reach these sacred shrines. The yatra is
          spiritually significant, and each temple is associated with unique
          legends and mythological stories. The Panch Kedar Yatra provides an
          opportunity for devotees to seek blessings and connect with the divine
          amidst the serene beauty of the Himalayas.
        </p>
      ),
    },
    {
      id: "short-itinerary",
      title: "Short Itinerary",
      content: (
        <p>
          Day 1: Arrival in Haridwar/Rishikesh.
          <br />
          Day 2: Rishikesh to Guptkashi.
          <br />
          Day 3: Guptkashi to Kedarnath to Guptkashi.
          <br />
          Day 4: Guptkashi to Madhyamaheshwar to Guptkashi.
          <br />
          Day 5: Guptkashi to Tungnath to Rudranath to Dumuk.
          <br />
          Day 6: Dumuk to Kalpeshwar to Rudraprayag.
          <br />
          Day 7: Rudraprayag to Haridwar/Rishikesh.
        </p>
      ),
    },
    {
      id: "detailed-itinerary",
      title: "Detailed Itinerary",
      content: (
        <ul>
          <li>Day 1: Arrival in Haridwar/Rishikesh</li>
          <p>
            Arrive in Haridwar or Rishikesh, two major starting points for the
            Panch Kedar Yatra. Check into your hotel and relax after the
            journey. In the evening, attend the captivating Ganga Aarti at the
            banks of the Ganges for a spiritual experience.
          </p>
          <br />

          <li>Day 2: Rishikesh to Guptkashi</li>
          <p>
            After breakfast, proceed from Rishikesh to Guptkashi, a town
            situated in the Rudraprayag district. Enjoy the scenic drive that
            takes you through the picturesque landscapes of Uttarakhand. Arrive
            in Guptkashi, check into your hotel, and spend the evening at
            leisure.
          </p>
          <br />

          <li>Day 3: Guptkashi to Kedarnath to Guptkashi</li>
          <p>
            Start early in the morning and head to Gaurikund, the commencement
            point for the Kedarnath trek. Embark on the 16 km trek to Kedarnath
            or opt for a helicopter ride (subject to availability and weather
            conditions). Reach Kedarnath, visit the ancient Kedarnath Temple
            dedicated to Lord Shiva, and seek blessings. After spending some
            time at the temple and absorbing the spiritual energy, trek back to
            Gaurikund, and then drive back to Guptkashi for an overnight stay.
          </p>
          <br />

          <li>Day 4: Guptkashi to Madhyamaheshwar to Guptkashi</li>

          <p>
            Today, visit the enchanting Madhyamaheshwar Temple, the second of
            the Panch Kedar. Trek to Madhyamaheshwar Temple, dedicated to Lord
            Shiva in his belly form (nabhi). After seeking blessings and
            exploring the serene surroundings, trek back to Guptkashi for an
            overnight stay.
          </p>
          <br />

          <li>Day 5: Guptkashi to Tungnath to Rudranath to Dumuk</li>

          <p>
            Proceed to Tungnath, the highest Shiva temple in the world, and also
            one of the Panch Kedar. Trek to Tungnath and then continue to
            Rudranath, which holds immense spiritual significance as it is
            believed to be the face (mukh) of Lord Shiva. After visiting
            Rudranath, continue trekking to Dumuk and rest for the night.
          </p>
          <br />

          <li>Day 6: Dumuk to Kalpeshwar to Rudraprayag</li>
          <p>
            On this day, visit the last of the Panch Kedar, Kalpeshwar Temple,
            associated with Lord Shiva's hair (jata). After seeking blessings at
            Kalpeshwar, proceed to Rudraprayag, a town located at the confluence
            of the Alaknanda and Mandakini rivers. Check into your hotel in
            Rudraprayag and spend the night there.
          </p>
          <br />

          <li>Day 7: Rudraprayag to Haridwar/Rishikesh</li>
          <p>
            On the last day of the Panch Kedar Yatra, return to Haridwar or
            Rishikesh. Conclude the spiritual journey filled with divine
            blessings, unforgettable experiences, and cherished memories.
          </p>
          <br />

          <br />
        </ul>
      ),
    },
  ];

  return (
    <>
      <Carousel slides={slides} />

      <div className="trek-details">
        <div className="trek-into">
          <h2>Panch-Kedar Yatra</h2>
          <p>
            The Panch Kedar Yatra is a sacred pilgrimage journey located in the
            mystical and picturesque state of Uttarakhand, India. It is an
            important Hindu pilgrimage that involves visiting five ancient
            temples dedicated to Lord Shiva, known as the Panch Kedar. Each of
            these temples holds immense spiritual significance and is believed
            to be associated with various legends from Hindu mythology. The
            Panch Kedar Yatra is undertaken by devout Hindus seeking spiritual
            enlightenment, blessings, and the chance to immerse themselves in
            the pristine beauty of the Himalayas. The five temples included in
            the Panch Kedar Yatra are: 1. Kedarnath: The first and most famous
            temple of the yatra is Kedarnath, located at an altitude of 3,583
            meters above sea level. Situated amidst the snow-clad peaks near the
            Mandakini River, this temple is dedicated to Lord Kedarnath, an
            incarnation of Lord Shiva. The temple's unique architecture and
            spiritual ambiance attract pilgrims from all over the world. The
            journey to Kedarnath involves a challenging trek from Gaurikund,
            which adds to the pilgrims' sense of accomplishment and devotion. 2.
            Madhyamaheshwar: The second temple in the yatra is Madhyamaheshwar,
            located at an altitude of 3,497 meters. This temple is believed to
            be the place where the navel of Lord Shiva appeared after he
            disappeared at Kedarnath. The temple's remote location adds to its
            mystical allure, and reaching it requires a trek through
            breathtaking landscapes and lush meadows. 3. Tungnath: The third
            temple is Tungnath, situated at an elevation of 3,680 meters. It is
            the highest temple dedicated to Lord Shiva and is considered the arm
            (bahu) of Lord Shiva among the Panch Kedar. The trek to Tungnath is
            relatively moderate and offers stunning views of the snow-clad peaks
            of the Himalayas. 4. Rudranath: The fourth temple in the yatra is
            Rudranath, located at an altitude of 2,286 meters. It is believed to
            be the face (mukh) of Lord Shiva. The trek to Rudranath takes
            pilgrims through dense forests and beautiful meadows, providing an
            opportunity to experience the tranquility of nature. 5. Kalpeshwar:
            The fifth and final temple is Kalpeshwar, situated at an elevation
            of 2,200 meters. This temple is believed to be the hair (jata) of
            Lord Shiva. It is the only Panch Kedar temple accessible throughout
            the year, making it a prominent destination for pilgrims seeking the
            Lord's blessings. The Panch Kedar Yatra typically takes place during
            the summer months, from May to October, when the temples are
            accessible due to favorable weather conditions. The pilgrimage
            journey is physically demanding but spiritually rewarding, as it
            allows devotees to connect with nature and seek the divine grace of
            Lord Shiva in the serene and awe-inspiring settings of the
            Himalayas. The Panch Kedar Yatra is an unforgettable experience that
            leaves a lasting impression on the hearts of pilgrims, instilling a
            sense of devotion, humility, and reverence for the divine.
          </p>
          <div>
            <TripPlanner sections={sections} />
          </div>
        </div>

        <div className="trek-sidebar">
          {slidebar.map((slidebar) => (
            <SlideBarCard
              key={slidebar.title}
              title={slidebar.title}
              para={slidebar.para}
              imageUrl={slidebar.imageUrl}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default PanchKedarYatra;
