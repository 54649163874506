import React from 'react';
import { FaFacebook, FaYoutube, FaInstagram } from 'react-icons/fa';
import logo from '../assets/logo.png';
import '../styles/Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <img src={logo} alt="" className="logo" />
          <p>Busy Boots Travel: Embark on unforgettable journeys with us! We curate thrilling adventures, personalized tours, and dreamy getaways, ensuring your wanderlust is satisfied every step of the way. Let's explore together!</p>
        </div>

        <div className="footer-section">
          <h2>Contact Us</h2>
          <p>Email: ajaymountainsoul@gmail.com</p>
          <p>Address: Dehradun, Uttarakhand</p>
          <p>Phone: +91 80064 04570

</p>
        </div>

        <div className="footer-section">
          <h2>Follow Us</h2>
          <div className="social-icons">
          <a href="https://www.facebook.com/busybootstravel/"> <FaFacebook className="social-icon" size={30} /></a> 
          <a href="">  <FaYoutube className="social-icon" size={30} /></a>
           <a href="https://www.instagram.com/busy.boots.travel/?hl=en"> <FaInstagram className="social-icon" size={30} /></a>
          </div>
        </div>
      </div>

      <hr className="divider" />

      <div className="attribution">
      <a href="https://www.linkedin.com/in/jitendra-rawat-10b472121/">  <p>Developed by - Jitendra Rawat</p></a>
      </div>
    </footer>
  );
};

export default Footer;
