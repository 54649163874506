import React from "react";
import Carousel from "./Carousel";
import SlideBarCard from "./SlideBarCard";
import TripPlanner from "./TripPlanner";


import "../styles/AllTrek.css";

const PangarchulaTrek = () => {
  const slides = [
    {
      id: 1,
      image: 'https://images.unsplash.com/photo-1605262221574-a1d0fc44aac0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80',
    },
    {
      id: 2,
      image: 'https://images.unsplash.com/photo-1511715885542-a3713331633d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80',
    },
    {
      id: 3,
      image: 'https://images.unsplash.com/photo-1557652696-0fd8a35b0d62?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80',
    },
  ];

  const slidebar = [
    {
      title: "Accomodation",
      para: "Hotel,Camps",
      imageUrl:'https://cdn-icons-png.flaticon.com/128/489/489870.png'
    },

    {
      title: "Best Season",
      para: "Round The Year",
      imageUrl: "https://cdn-icons-png.flaticon.com/128/4360/4360322.png",
    },
    {
      title: "Transportation",
      para: "Taxi",
      imageUrl: "https://cdn-icons-png.flaticon.com/128/75/75780.png",
    },

    {
      title: "Meals",
      para: "Breakfast,Lunch,Dinner",
      imageUrl: "https://cdn-icons-png.flaticon.com/128/857/857681.png",
    },

    {
      title: "Activities",
      para: "Hiking,Camping",
      imageUrl: "https://cdn-icons-png.flaticon.com/128/2280/2280969.png",
    },





  ];

  const sections = [
    {
      id: "overview",
      title: "Overview",
      content: (
        <p>
          Area: The Upper Alkananda valley Garhwal Himalayas <br />
          Duration: 5 Nights / 6 Days
          <br />
          Max Altitude: Above 4600 m.
          <br />
          Grade: Easy to Moderate
          <br />
          Season: Round the year
          <br />
          Getting there: Train station – Haridwar & Airport – Jolly Grant
          Airport Dehradun
          <br />
          Destinations covered: Delhi – Hardwar – Rishikesh – Joshimath – Auli –
          Tali – Gailger – Khulara – Tapovan.
          <br />
        </p>
      ),
    },
    {
      id: "short-itinerary",
      title: "Short Itinerary",
      content: (
        <p>
          Day 1 : Haridwar Railway station / Dehradun Airport – Joshimath (2750
          m) drive 278 km / 9 hour hotel.
          <br />
          Day 2 : Joshimath – Dhak – Khulara (2940 m) drive 12 km 30 min, trek,
          5 hrs camp.
          <br />
          Day 3 : Khulara – Gailgar (3245) – Kuaripass (3640 m) – Khulara, trek
          back, 7 hour camp.
          <br />
          Day 4 : Khulara – Summit Pangarchulla (5000 m) - Khulara, 8 -9 hr both
          ways, camp.
          <br />
          Day 5 : : Khulara – Auli Road head (2750 m) trek 5-6 hr – Joshimath
          drive 14 km, 30 min hotel.
          <br />
          Day 6 : Joshimath - Rishikesh 268 km / 8 - 9 hour or Dehradun Airport
          / Haridwar Railway Station drop.
          <br />
        </p>
      ),
    },
    {
      id: "detailed-itinerary",
      title: "Detailed Itinerary",
      content: (
        <ul>
          <li>
            Day 1 : Haridwar Railway station / Dehradun Airport – Joshimath
            (2750 m) drive 278 km / 9 hour hotel.
          </li>
          <p>
            Morning arrive at DEHRADUN Airport / HARDWAR railway station by
            overnight train, meet and assistance by our representative and board
            on prearranged cabs to drive towards Joshimath, today the entire
            drive goes along the beautifully carved valleys of the Ganges and
            the Alkananda rivers, encountering the 5 sacred Prayags
            (confluences). We make short stops for lunch and photographs on the
            way. Arriving Joshimath, check in at hotel.
          </p><br />

          <li>
            Day 2 : Joshimath – Dhak – Khulara (2940 m) drive 12 km 30 min,
            trek, 5 hrs camp.
          </li>

          <p>
            Morning after breakfast, take a short drive of 30 mins to Dhak,
            trekking start off point. Short briefing on the terrain and route by
            our local guide, you are heading off to Khualra camp, past through
            some beautiful villages and rich mixed Himalayan forest patches.
            Reaching Khualara, welcome tea and coffee, get settled in tent.
            Later enjoy a delicious snacks followed by dinner. Lunch will be
            packed.
          </p><br />

          <li>
            Day 3 : Khulara – Gailgar (3245) – Kuaripass (3640 m) – Khulara,
            trek back, 7 hour camp.
          </li>

          <p>
            Morning after breakfast, a well maintained nomadic trail leads you
            with a gentle zig- zag ascent until you hit a beautiful green
            meadow, it’s a lovely spot to catch your breath back and rest .
            Later start steep climb to Gailgar. Reaching Gailgar enjoy your
            packed lunch and relax for a few minute. After the lunch, you will
            be taken to explore Kuaripass,that offers one of the most peculiar
            view of snow capped mountains of Badrinath range in west and Nanda
            Devi range in the east. After a quality time on Kuaripass, back to
            Camp, Take Tea, coffee and snacks and relax in your tent or you can
            enjoy watching sun rays reflecting on the peaks. Later take dinner
            and go to bed. This is day can be significantly used as a good
            acclimatization day prior to climbing Pangarchulla Summit. Today
            ascent and descent is going to tone up your body to adopt mountain
            conditions.
          </p><br />

          <li>
            Day 4 : Khulara – Summit Pangarchulla (5000 m) - Khulara, 8 -9 hr
            both ways, camp.
          </li>

          <p>
            Take an early morning wake call with tea, coffee and cookies and set
            for climbing with packed breakfast. The ascent is steep soon from
            the camp and gets tougher as you progress up, negotiating bolder and
            loose soil that slows down your pace. There is no water on the way
            to the summit so make sure you have enough water for the day.
            Hitting the summit take photographs and enjoy the pride moment of
            being on the top and later descend to Khulara camp for lunch.
            Descending down until the camp is easier but never try to strides or
            run, this might result in a higher risk of getting injured your knee
            or ankle, especially when there is snow on the slopes. So follow
            instructions and enjoy the fun of being out there.
          </p><br />

          <li>
            Day 5 : : Khulara – Auli Road head (2750 m) trek 5-6 hr – Joshimath
            drive 14 km, 30 min hotel.
          </li>

          <p>
            After breakfast a well described nomadic trail takes you always down
            to Auli, via a mesmerizing alpine meadow of Gorson, is a picturesque
            spot to view a wider & closer glimps of mount Nanda Devi and and
            Badrinath ranges. Reaching at Auli Top, you get a good opportunity
            to see a larger view of Nanda Devi (7820m) the most sacred peak of
            the region as Kanchenchanga revered in Sikkim. Reaching Auli, Road
            head, catch your cab to Joshimath.
          </p><br />

          <li>
            Day 6 : Joshimath - Rishikesh 268 km / 8 - 9 hour or Dehradun
            Airport / Haridwar Railway Station drop.
          </li>

          <p>
            Today you will drive down to Rishikesh : Drop Haridwar Railway
            station / Dehradun Airport for final depart.
          </p><br />
        </ul>
      ),
    },
  ];

  return (
    <>
      <Carousel slides={slides} />

      <div className="trek-details">
        <div className="trek-into">
          <h2>Pangarchula Trek</h2>
          <p>
            Pangarchulla summit brings various levels of challenges according to
            seasons as December to May. In winter months negotiating on knee
            deep snow layers and June to November involves in form of boulders
            and loose soil. The climb is arduous but definitely not strenuous,
            it doesn’t demand any serious mountaineering skill, and even a naive
            trekker with basic hiking skill can easily hit the summit. At
            Lammergeier Himalayan Experience, you are carrying out this climbing
            trip under the guidance of our trained and certified guides equipped
            with necessary climbing gears and updated technique of serious
            mountain climbing. They fix rope, belaying you on carabineers and
            assist you on the entire climb; however the climb is technically not
            so formidable. From top of the summit, you get a spectacular wider
            360 view of numerous jagged snow capped peaks and viewing Nanda Devi
            (7820m) is a prime attraction from here, other closer sightings are
            Nilkant (6596 m), Nar – Narayan (5968 m), Mana (7268m), Kamet
            (7756m), Abi Gamin (7350m), Neelgiri (6475 m), Ghori (6706m), Hathi
            (6724m), Dronagiri (7066m) and Chaukhambha (7140m) and many more in
            a huge rim of mountains.
          </p>
          <div>
            <TripPlanner sections={sections} />
          </div>
        </div>

        <div className="trek-sidebar">
          {slidebar.map((slidebar) => (
            <SlideBarCard
              key={slidebar.title}
              title={slidebar.title}
              para={slidebar.para}
              imageUrl={slidebar.imageUrl}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default PangarchulaTrek;
