import {BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './components/Home.jsx';
import Footer from './components/Footer.jsx';
import TravelPackages from './components/TravelPackages.jsx';
import Activities from './components/Activities.jsx';
import Navbar from './components/Navbar.jsx';




import './styles/App.css'

import './styles/Home.css'
import Kuaripass from './components/Kuaripass.jsx';
import SatopanthTrek from './components/SatopanthTrek.jsx';
import BagniGlacierTrek from './components/BagniGlacierTrek.jsx';
import PangarchulaTrek from './components/PangarchulaTrek.jsx';
import About from './components/About.jsx';
import Contact from './components/Contact.jsx';

import SpiritualTour from './components/SpiritualTour.jsx';
import Hiking from './components/Hiking.jsx'
import BadriKedarYatra from './components/BadriKedarYatra.jsx';
import PanchKedarYatra from './components/PanchKedarYatra.jsx';
import OffbeatTrek from './components/OffbeatTrek.jsx';
import RoadTrips from './components/RoadTrips.jsx';
import BlogCharDham from './components/BlogCharDham.jsx';
import BlogAdiKailash from './components/BlogAdiKalaish.jsx';
import BlogSacredSites from './components/BlogSacredSites.jsx';
import BlogHiking from './components/BlogHiking.jsx';





function App(){

  return (
<Router>

  <Navbar />

  <Routes>

    <Route  path='/' element={<Home />} />
    <Route  path='/packages' element={<TravelPackages />} />
    <Route  path='/activities' element={<Activities />}/>
    <Route  path='/kuari-pass' element={<Kuaripass />}/>
    <Route  path='/satopanth-trek' element={<SatopanthTrek />}/>
    <Route  path='/bagni-glacier-trek' element={<BagniGlacierTrek />}/>
    <Route  path='/pangarchula-trek' element={<PangarchulaTrek />}/>
    <Route  path='/about' element={<About />}/>
    <Route  path='/contact' element={<Contact />}/>
    <Route  path='/spiritual-tour' element={<SpiritualTour />}/>
    <Route  path='/hiking' element={<Hiking />}/>
    <Route  path='/badri-kedar-yatra' element={<BadriKedarYatra />}/>
    <Route  path='/panch-kedar-yatra' element={<PanchKedarYatra />}/>
    <Route  path='/offbeat-trek' element={<OffbeatTrek />}/>
    <Route  path='/road-trips' element={<RoadTrips />}/>
    
    <Route  path='/blogs/char-dham-yatra' element={<BlogCharDham />}/>
    <Route  path='/blogs/adi-kailash-yatra' element={<BlogAdiKailash />}/>
    <Route  path='/blogs/sacred-sites-of-uttrakhand' element={<BlogSacredSites />}/>
    <Route  path='/blogs/hiking-in-uttrakhand' element={<BlogHiking />}/>
  </Routes>

  <Footer />
  </Router> 
     
  );
}

export default App;