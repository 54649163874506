import React from 'react';
import '../styles/TravelPackageCard.css'


const TravelPackageCard = ({ title, description, price, imageUrl,button }) => {
  return (
    <div className="card ">
      <img src={imageUrl} alt={title} className="card-image" />
      <div className="card-content">
        <h2 className="card-title">{title}</h2>
        <p className="card-description">{description}</p>
        <p className="card-price"><span style={{ color: '#333' }}>Starting at</span>  ₹{price}</p>
        <button>{button}</button>
      </div>
    </div>
  );
};

export default TravelPackageCard;
