import React from "react";
import Carousel from "./Carousel";
import SlideBarCard from "./SlideBarCard";
import TripPlanner from "./TripPlanner";

import img1 from "../assets/k.jpg";
import img2 from "../assets/kk.jpg";
import img3 from "../assets/kkk.jpg";

import "../styles/AllTrek.css";

const Kuaripass = () => {
  const slides = [
    {
      id: 1,
      image: img1,
    },
    {
      id: 2,
      image: img2,
    },
    {
      id: 3,
      image: img3,
    },
  ];

  const slidebar = [
    {
      title: "Accomodation",
      para: "Hotel,Camps",
      imageUrl: "https://cdn-icons-png.flaticon.com/128/489/489870.png",
    },

    {
      title: "Best Season",
      para: "Round The Year",
      imageUrl: "https://cdn-icons-png.flaticon.com/128/4360/4360322.png",
    },

    {
      title: "Meals",
      para: "Breakfast,Lunch,Dinner",
      imageUrl: "https://cdn-icons-png.flaticon.com/128/857/857681.png",
    },

    {
      title: "Activities",
      para: "Hiking,Camping",
      imageUrl: "https://cdn-icons-png.flaticon.com/128/2280/2280969.png",
    },
  ];

  const sections = [
    {
      id: "overview",
      title: "Overview",
      content: (
        <p>
          Area: Garhwal Himalayas
          <br />
          2 NIGHTS GUEST HOUSE & 3 NIGHTS IN CAMP.
          <br />
          Max Altitude: Above 3450 m<br />
          Grade: Easy to Moderate
          <br />
          Season: April – June – July & Sept – March (opt Rational round the
          year, except July mid – Sep mid)
          <br />
          Destinations covered: Hardwar – Rishikesh – Joshimath – Auli – Tali –
          Gailger – Khulara – Tapovan.
        </p>
      ),
    },
    {
      id: "short-itinerary",
      title: "Short Itinerary",
      content: (
        <p>
          Day 1 : Haridwar Railway station / Dehradun Airport – Joshimath (1819
          m) drive 278 km / 9 hour Hotel. <br />
          Day 2 : Joshimath - Auli (2750 m) – Tali Forest Camp (2900 m) drive 12
          km 30 min, trek, 5 hrs camp. <br />
          Day 3 : Tali Forest Camp – Khulara (2950 m) trek 3 - 4 hrs hour camp.
          <br />
          Day 4 : Khulara – Kuari Pass (3450 m) - Khulara 6 – 7 hour camp.
          <br />
          Day 5 : Khulara – Karchi Village Road head (2250 m) drive – Joshimath
          16 km, 30 min hotel.
          <br />
          Day 6 : Joshimath - Rishikesh 268 km / 8 - 9 hour or Dehradun Airport
          / Haridwar Railway station drop.
        </p>
      ),
    },
    {
      id: "detailed-itinerary",
      title: "Detailed Itinerary",
      content: (
        <ul>
          <li>
            Day 1 : Haridwar Railway station / Dehradun Airport – Joshimath
            (1819 m) drive 278 km / 9 hour Hotel.
          </li>
          <p>
            Arrive on Haridwar Railway station and board on the cab. Today’s the
            entire drive goes along the beautifully carved valleys of the Ganges
            and the Alkananda rivers, encountering the 5 sacred Prayags
            (confluences). We make short stops for lunch and photographs on the
            way. Arriving at Joshimath , check in at hotel / guest house for
            dinner and overnight stay.
          </p>
          <br />

          <li>
            Day 2 : Joshimath - Auli (2750 m) – Tali Forest Camp (2900 m) drive
            12 km 30 min, trek, 5 hrs camp.
          </li>
          <p>
            Morning take a short drive of 30 mins to Auli, trekking start off
            point. Short briefing about the terrain and route by our local
            guide, you are heading off to Tali camp with a steeper stretch of
            1:30 hour to Auli Top and then the trail passes through a few rich
            mixed Himalayan wooded patches and sublime alpine meadows carpeted
            with varied tiny flowers and an enchanting array of huge sky kissing
            snowy mountains at in front of your eyes.
          </p>
          <br />

          <li>
            Day 3 : Tali Forest Camp – Khulara (2950 m) trek 3 - 4 hrs hour
            camp.
          </li>
          <p>
            The trail mainly moves through woods of pine, rhododendron and
            Himalayan maple. This is a quite easy hiking day through the lovely
            woods of pine and oaks. This ecologically rich haven is an ideal
            home to the different wild mammals and birds. Reaching Khulara,
            enjoy the natural beauty with a huge pastoral meadows and herds of
            sheep and goats, as the snow melts in summer the local shepherds go
            high reaches to feed their animal in Bugials.
          </p>
          <br />

          <li>
            Day 4 : Khulara – Kuari Pass (3450 m) - Khulara 6 – 7 hour camp.
          </li>
          <p>
            Its going to be a longer days but offers an amazing view of numerous
            snow capped mountains , deep and dark overlooking valleys settled
            with tiny villages and colorful farm lands. In the beginning the
            climb is a bit tough due to rising altitude but the vistas around
            there never let you down and eventually you reach Kuaripass, the
            highest point of the trek which offers an amazing uninterrupted view
            of numerous snow capped mountains and lovely expanse sprawling
            Bugials dotted with rhododendron bushes and varied tiny alpine
            flowers. Lunch will be packed. After spending some time on the pass,
            head back Khulara camp which is always down to the camp site.
            Reaching at camp you will be served tea and snack and dinner will be
            followed timely.
          </p>
          <br />

          <li>
            Day 5 : Khulara – Karchi Village Road head (2250 m) drive –
            Joshimath 16 km, 30 min hotel.
          </li>

          <p>
            After breakfast a well maintained nomadic trail takes you always
            down to Karchi road head crossing through scenic and old villages of
            Karchi and Tugasi. Lunch will be packed.
          </p>
          <br />

          <li>
            Day 6 : Joshimath - Rishikesh 268 km / 8 - 9 hour or Dehradun
            Airport / Haridwar Railway station drop.
          </li>
          <p>
            Today you will drive down to Rishikesh : drop Haridwar Railway
            station / Dehradun Airport for final depart.
          </p>
          <br />
        </ul>
      ),
    },
  ];

  return (
    <>
      <Carousel slides={slides} />

      <div className="trek-details">
        <div className="trek-into">
          <h2>Kuari Pass Trek</h2>
          <p>
            The Kuari (virgin) Pass is a highly varied & scenic trek which
            passes through three lower Passes & four major rivers – the
            Alkananda, the Nandakini, the Bir ganga & the Dhauli Ganga all are
            the tributaries of the Ganges. The pass or “KHAL” is approached on
            nomad trail from Joshimath – Badrinath along the pilgrim’s route.
            This trek can be undertaken from Auli or Karchi village. Kuari pass
            is one of the most beautiful treks, famous for the enchanting views
            of the snow clad peaks of Neelkanth (6596m),Kamet (7756m), Mukut
            Parbat, Mana, Nilgiri Parbat, Trishul (7120m), Hardeol, Dronagiri,
            Rishi Pahar, Kalanka, Chaukhamba (7128m), Lampak, Changabang
            (6864m), Ronthi, Bithartoli and others. On a clear day most of the
            time you are trekking in the company of these mesmerizing mountains
            giving you a thrilling experience of the mighty Himalaya,
            particularly the Nanda Devi Massif (7820m). These mountains are
            though far away, yet appear very close. The trail was named after
            Lord Curzon, a British viceroy, ‘Curzon Trail’, who was a keen
            trekker & it is said that in 1905 the path was specially improved so
            that he could trek. Lord Curzon the British Viceroy, in 1905 set out
            an excursion in this wonder land and was simply found himself
            captivated by the mesmerizing scenic beauty of the enormously high
            snow capped towering peaks of the Nanda Nandadevi sanctuary and
            other great massifs like Kamet, Mana, NAR Narayan, the twin of Hathi
            and Ghori (the Elephant & Horse) Satopanth, Chaukhambha and many
            more……..in the north separating India from Tibet by an awesome wall
            protects as an alert soldier. Beside that you are effortlessly
            fascinated by its rich aromatic smell of medicinal herbs and
            rhododendron and world famed rolling slopes of Auli and Gorson
            Bugials (pastoral alpine slopes) and of course a number of remote
            villages, despite being heavily infected from modern socialization,
            these villages are still hold their culture and traditional traits.
          </p>
          <div>
            <TripPlanner sections={sections} />
          </div>
        </div>

        <div className="trek-sidebar">
          {slidebar.map((slidebar) => (
            <SlideBarCard
              key={slidebar.title}
              title={slidebar.title}
              para={slidebar.para}
              imageUrl={slidebar.imageUrl}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Kuaripass;
