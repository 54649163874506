import React from 'react'
import '../styles/SideBarCard.css'

const SlideBarCard = ({ imageUrl, title, para }) => {
  return (
    <>
<div className="service-card">
            <img src={imageUrl} className="sidebar-service-icon" />
            <h3 className="sidebar-service-name">{title}</h3>
            <p className="sidebar-service-description">{para}</p>
          </div>
    </>
  )
}

export default SlideBarCard