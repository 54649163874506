import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from '../assets/logo.png'
import { AiOutlineMenu } from 'react-icons/ai';
import '../styles/Navbar.css';

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const closeMenu = () => {
    setShowMenu(false);
  };

  return (
    <nav className="navbar">
      <div >
       <img className="navbar-brand" src={logo} alt="" />
      </div>

      <button className="navbar-toggle" onClick={toggleMenu}>
        <AiOutlineMenu size={24} />
      </button>

      <ul className={`navbar-links ${showMenu ? 'active' : ''}`}>
        <li>
          <Link to="/" onClick={closeMenu}>Home</Link>
        </li>
        <li>
          <Link to="/packages" onClick={closeMenu}>Packages</Link>
        </li>
        <li>
          <Link to="/activities" onClick={closeMenu}>Activities</Link>
        </li>
        <li>
          <Link to="/about" onClick={closeMenu}>About</Link>
        </li>
        <li>
          <Link to="/contact" onClick={closeMenu}>Contact</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
