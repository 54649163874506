import React from 'react'
import { Link } from 'react-router-dom';


import TravelPackages from './TravelPackages';
import Services from './Services';
import CarouselHome from "./CarouselHome";
import Banner from './Banner';
import '../styles/Home.css'


import img1 from '../assets/1.jpg'
import img2 from '../assets/2.jpg'
import img3 from '../assets/3.jpg'
import Blogs from './Blogs';
import WhyChooseUs from './WhyChooseUs';
import ImageGrid from './ImageGrid';






const Home = () => {


  const slides = [
    {
      id: 1,
      image: img1,
      title:'Spiritual Tour',
      description: 'Embark on a Soul-Enriching Journey: Discover Serenity and Enlightenment on our Spiritual Tours.',
      button:  <Link to="/spiritual-tour">
         <button className='card-button' >Explore Now</button>
    
    </Link>
       
     
     
    },
    {
      id: 2,
      image: img2,
      title: 'Hiking',
      description: `Unleash the Adventurer Within: Join Us for Thrilling Hiking Expeditions amidst Nature's Majesty`,
      button:  <Link to="/hiking">
     <button className='card-button' >Hike Now</button>
    </Link>
       
    },

    {
      id: 3,
      image:'https://images.unsplash.com/photo-1646584336975-5cee3191f95d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1332&q=80',
      title: 'Travelling',
      description: 'Explore the most beautiful destinations in the world!',
      button:  <Link to="/activities">
        <button className='card-button' >Travel Now</button>
    
    </Link>
     
       
    },
    
  ];



return (
<>



<CarouselHome slides={slides} />
<ImageGrid />
<TravelPackages />
<WhyChooseUs />
<Services />
<Blogs />
<Banner />




</>
  )
}

export default Home