import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/ImageGrid.css'; // Create a CSS file for your custom styles

const ImageGrid = () => {
  return (
    <div className="image-grid">
        

    <div className="image-grid-item" id='image-grid-item-1'>
   <Link to={'/spiritual-tour'}>

<img src="https://e1.pxfuel.com/desktop-wallpaper/123/534/desktop-wallpaper-badrinath-temple-badrinath.jpg" alt="Image 1" />
        
  <h2 className='heading'>Spiritual Tour</h2>
  </Link>
      </div>
    
      



      <div className="image-grid-item" id='image-grid-item-5'>
      <Link to={'/hiking'}>
        <img src="https://e0.pxfuel.com/wallpapers/1021/474/desktop-wallpaper-hiking-people-extreme-mountains-49029.jpg" alt="Image 5" />
        <h2 className='heading'>Hiking</h2>
        </Link>
      </div>


      <div className="image-grid-item" id='image-grid-item-2'>
      <Link to={'/offbeat-trek'}>
        <img src="https://e0.pxfuel.com/wallpapers/398/367/desktop-wallpaper-forest-trail-landscape-trail-nature-forest.jpg" alt="Image 2" />
        <h2 className='heading'>Off-Beat Treks</h2>
        </Link>
      </div>
  
      <div className="image-grid-item" id='image-grid-item-4'>
      <Link to={'/road-trips'}>
        <img src="https://e1.pxfuel.com/desktop-wallpaper/668/19/desktop-wallpaper-leh-ladakh-ladakh-bike.jpg" alt="Image 4" />
        <h2 className='heading'>Road Trips</h2>
        </Link>
      </div>
     
    </div>
  );
};

export default ImageGrid;
