import React from "react";
import { Link } from "react-router-dom";

import TravelPackageCard from "./TravelPackageCard";


import '../styles/TravelPackages.css'

const TravelPackages = () => {
  const packages = [
    {
      title: "Badrinath-Kedarnath Yatra",
      description:
        "Sacred pilgrimage to Badrinath and Kedarnath temples, revered Hindu shrines in Uttarakhand, India.",
      price: 10000,
      imageUrl:
        "https://images.unsplash.com/photo-1612438214708-f428a707dd4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80",
      button: (
        <Link to="/badri-kedar-yatra">
          <button className="card-button">Book Now</button>
        </Link>
      ),
    },

    {
      title: "Kuari Pass Trek",
      description:
        "An awe-inspiring Himalayan journey with stunning vistas, alpine meadows, and breathtaking views of Nanda Devi and other peaks.",
      price: 9000,
      imageUrl:
        "https://images.unsplash.com/photo-1649999132739-52f8da421441?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80",
      button: (
        <Link to="/kuari-pass">
          <button className="card-button">Book Now</button>
        </Link>
      ),
    },

    {
      title: "Satopanth Trek",
      description:
        "Challenging Himalayan trek in Uttarakhand, India, leading to a pristine glacial lake, known for its scenic beauty and spiritual significance.",
      price: 12000,
      imageUrl:
        "https://images.unsplash.com/photo-1545652985-5edd365b12eb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
      button: (
        <Link to="/satopanth-trek">
          <button className="card-button">Book Now</button>
        </Link>
      ),
    },

    {
      title: "Bagni Glacier Trek",
      description:
        "Bagni Glacier trek offers a thrilling adventure, exploring the icy beauty and pristine landscapes of the Himalayas",
      price: 13000,
      imageUrl:
        "https://images.unsplash.com/photo-1596953866065-0cffbc735059?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80",
      button: (
        <Link to="/bagni-glacier-trek">
          <button className="card-button">Book Now</button>
        </Link>
      ),
    },

    {
      title: "Pangarkula Trek",
      description:
        "Adventurous Himalayan trek in Uttarakhand, India, offering stunning views of snow-capped peaks, glaciers, and lush valleys.",
      price: 8000,
      imageUrl:
        "https://images.unsplash.com/photo-1611154046036-cd91e50978be?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
      button: (
        <Link to="/pangarchula-Trek">
          <button className="card-button">Book Now</button>
        </Link>
      ),
    },

    {
      title: "Panch Kedar Yatra",
      description:
        "Sacred Uttarakhand pilgrimage visiting five Shiva temples: Kedarnath, Madhyamaheshwar, Tungnath, Rudranath, and Kalpeshwar.",
      price: 15000,
      imageUrl:
        "https://images.unsplash.com/photo-1623952146070-f13fc902f769?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80",
      button: (
        <Link to="/panch-kedar-yatra">
          <button className="card-button">Book Now</button>
        </Link>
      ),
    },
  
    

  ];

  return (
    <div>
      <h4 className="travel-packages-title">Discover the Enchanting Uttarakhand: Unforgettable Travel Packages for Adventurers and Nature Lovers.</h4>
      <div className="package-cards packages-container">
        {packages.map((packages) => (
          <TravelPackageCard
            key={packages.title}
            title={packages.title}
            description={packages.description}
            price={packages.price}
            imageUrl={packages.imageUrl}
            button={packages.button}
          />
        ))}
      </div>
    </div>
  );
};

export default TravelPackages;
