import React from 'react'
import '../styles/About.css'
import logo from '../assets/logo.png'
import img1 from '../assets/ajay.jpeg'
import img2 from '../assets/jsr.jpeg'
import img3 from '../assets/jittu.png'

const About = () => {

  const teamMembers = [
    {
      name: 'Ajay Rawat',
      role: 'Founder',
    
      photo: img1,
    },
    {
      name: 'Jaman Singh Rawat',
      role: 'Co-Founder',
   
      photo: img2,
    },
    
    {
      name: 'Jitendra Rawat',
      role: 'Technical Consultant',
   
      photo: img3,
    },
    
    
  ];

  return (
  <>
   <div className="about-us-container">
      <div className="about-us-header">
        <h1>About Us</h1>
        <p>Busy Boots Travel is a travel company that specializes in hiking and trekking tours in Uttarakhand, India. We offer a wide variety of tours to suit all levels of experience, from beginner to experienced trekkers. Our tours are all led by experienced guides who are passionate about sharing their love of the Himalayas with others.

We believe that everyone should have the opportunity to experience the beauty and wonder of the Himalayas. That’s why we offer our tours at an affordable price. We also offer a variety of payment options to make it easy for you to book your trip.

When you book a tour with Busy Boots Travel, you can be sure that you’re getting the best possible experience. We use only the highest quality equipment and we take safety very seriously. We also work with local communities to ensure that our tours have a positive impact on the environment.

If you’re looking for an unforgettable hiking or trekking experience in the Himalayas, then Busy Boots Travel is the perfect choice for you. Contact us today to book your trip!

Here are some of the things that make Busy Boots Travel different from other travel companies:

We offer a wide variety of tours to suit all levels of experience.
Our tours are all led by experienced guides who are passionate about sharing their love of the Himalayas with others.
We offer our tours at an affordable price.
We offer a variety of payment options to make it easy for you to book your trip.
We use only the highest quality equipment.
We take safety very seriously.
We work with local communities to ensure that our tours have a positive impact on the environment.
We hope to see you on one of our tours soon!</p>
      </div>
    
    
    </div>

    <div className="our-team-section">
      <h2>Our Team</h2>
      <div className="team-members">
        {teamMembers.map((member, index) => (
          <div key={index} className="team-member">
            <img src={member.photo} alt={member.name} />
            <h3>{member.name}</h3>
            <p>{member.role}</p>
            <p>{member.description}</p>
          </div>
        ))}
      </div>
    </div>

  </>
  )
}

export default About