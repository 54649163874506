import React from 'react'
import '../styles/SpiritualTour.css'
import ActivitiesCard from './ActivitiesCard';


import { Link } from 'react-router-dom';

const SpiritualTour = () => {
  
     const packages = [
    {
      title: 'Badri-Kedar  Yatra',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    
      imageUrl: 'https://cdn.pixabay.com/photo/2021/06/06/07/32/temple-6314509_1280.jpg',
      button:  <Link to="/badri-kedar-yatra">
      <button className="card-button">Book Now</button>
    </Link>
     
    
   
    
    },
    {
      title: 'Gangotri-Yamnotri ',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    
      imageUrl: 'https://images.unsplash.com/photo-1624807136278-e2973be118ff?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80',
      button:  <Link to="/">
      <button className="card-button">Book Now</button>
    </Link>
    
    },
    {
      title: 'Adi-Kailash Yatra',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    
      imageUrl: 'https://cdn.pixabay.com/photo/2018/03/02/08/57/mount-kailash-3192640_1280.jpg',
      button:  <Link to="/">
      <button className="card-button">Book Now</button>
    </Link>
    
    },
    {
      title: 'Panch Kedar Yatra',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    
      imageUrl: 'https://images.unsplash.com/photo-1612438214708-f428a707dd4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80',
      button:  <Link to="/panch-kedar-yatra">
      <button className="card-button">Book Now</button>
    </Link>
    
    }
  ]
  return (
    <>
    <div >
    <h2 className='packages-heading'>Spiritual Tour</h2>
      <div className="package-cards packages-container">
        {packages.map((packages) => (
          <ActivitiesCard
            key={packages.title}
            title={packages.title}
           imageUrl={packages.imageUrl}
            button={packages.button}
          />
        ))}
      </div>
    </div>

    
    </>
  )
}

export default SpiritualTour