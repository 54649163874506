import React from "react";
import { Link } from "react-router-dom";
import "../styles/Blogs.css";

const Blogs = () => {
  const blogs = [
    {
      id: 1,
      title:
        "Journey to the Abode of the Gods: Exploring the Spiritual Char Dham Yatra in Uttarakhand",
      content:
        " Nestled amidst the majestic Himalayas, Uttarakhand stands as a land of mystique, divine energy, and unparalleled beauty...",
      imageUrl:
        "https://images.pexels.com/photos/11305767/pexels-photo-11305767.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      link: "/blogs/char-dham-yatra",
    },
    {
      id: 2,
      title:
        "Adi Kailash Yatra: An Ethereal Journey to the Hidden Realm of Uttarakhand",
      content:
      <p>  Adi Kailash is a sacred mountain located in the Garhwal Himalayas of
      Uttarakhand, India. It is believed to be the abode of Lord Shiva...</p>,
      imageUrl:
        "https://e1.pxfuel.com/desktop-wallpaper/738/356/desktop-wallpaper-kailash-om-parvat.jpg",
      link: "/blogs/adi-kailash-yatra",
    },
    {
      id: 3,
      title: "A Spiritual Sojourn: Visiting the Sacred Sites of Uttarakhand",
      content:
       <p>Visiting the Sacred Sites of
       Uttarakhand Uttarakhand is a state in northern India that is home to
       some of the most sacred sites in Hinduism...</p>,
      imageUrl:
        "https://images.unsplash.com/photo-1642229103178-11e23542bd76?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1171&q=80",
      link: "/blogs/sacred-sites-of-uttrakhand",
    },
    {
      id: 4,
      title:
        "Culinary Journey through Uttarakhand: Tasting the Local Delicacies",
      content:
       <p>   Uttarakhand is a state in northern India that is home to some of the
       most beautiful and challenging trekking trails in the world. ...</p>,
      imageUrl:
        "https://images.pexels.com/photos/7846473/pexels-photo-7846473.jpeg?auto=compress&cs=tinysrgb&w=600",
      link: "/blogs/hiking-in-uttrakhand",
    },
  ];

  return (
    <>
      <div className="blog-heading">
        Discover captivating stories: Dive into our blogs for inspiration,
        knowledge, and endless adventures.
      </div>
      <div className="blog-container">
        {blogs.map((blog) => (
          <div key={blog.id} className="blog-card">
            <img src={blog.imageUrl} alt={blog.title} className="blog-image" />
            <h3 className="blog-title">{blog.title}</h3>
            <p className="blog-content">{blog.content}</p>
            <Link to={blog.link}>
              <button className="read-more-button">Read More</button>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
};

export default Blogs;
