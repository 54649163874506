import React from 'react'
import '../styles/ActivitiesCard.css'

const ActivitiesCard = ({ title, imageUrl,button }) => {
  return (
<>
<div className="card ">
      <img src={imageUrl} alt={title} className="card-image" />
      <div className="card-content">
        <h2 className="card-title">{title}</h2>
       <button>{button}</button>
      </div>
    </div>

</>   

  )
}

export default ActivitiesCard