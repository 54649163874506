import React from "react";
import Carousel from "./Carousel";
import SlideBarCard from "./SlideBarCard";
import TripPlanner from "./TripPlanner";


import { MdTent } from "react-icons/md";

import "../styles/AllTrek.css";

const BadriKedarYatra = () => {
  const slides = [
    {
      id: 1,
      image: 'https://images.unsplash.com/photo-1612438214708-f428a707dd4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80',
    },
    {
      id: 2,
      image: 'https://images.unsplash.com/photo-1601821139314-66a4d14cfc00?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=888&q=80',
    },
    {
      id: 3,
      image: 'https://images.unsplash.com/photo-1607271151332-2e6502de6bb6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1343&q=80',
    },
  ];

  const slidebar = [
    {
      title: "Accomodation",
      para: "Hotel,Camps",
      imageUrl: "https://cdn-icons-png.flaticon.com/128/489/489870.png",
    },

    {
      title: "Best Season",
      para: "May-June  September-November",
      imageUrl: "https://cdn-icons-png.flaticon.com/128/4360/4360322.png",
    },

    {
      title: "Meals",
      para: "Breakfast,Lunch,Dinner",
      imageUrl: "https://cdn-icons-png.flaticon.com/128/857/857681.png",
    },

    {
      title: "Activities",
      para: "Spiritual Tour",
      imageUrl: "https://cdn-icons-png.flaticon.com/128/2280/2280969.png",
    },
  ];

  const sections = [
    {
      id: "overview",
      title: "Overview",
      content: (
        <p>
          The Badrinath-Kedarnath Yatra is a revered pilgrimage in Uttarakhand,
          India. Devotees embark on a spiritually significant journey to visit
          the Badrinath Temple, dedicated to Lord Vishnu, and the Kedarnath
          Temple, dedicated to Lord Shiva. The yatra begins in Haridwar or
          Rishikesh, leading pilgrims through the picturesque Garhwal Himalayas.
          The Badrinath Temple, one of the Char Dham, features intricate
          carvings and draws devotees seeking blessings. The Kedarnath Temple,
          located at a challenging altitude, holds a stone lingam of Lord Shiva.
          The yatra, undertaken during summer, offers a blend of natural beauty
          and spiritual experiences, leaving pilgrims with a sense of
          fulfillment and inner peace.
        </p>
      ),
    },
    {
      id: "short-itinerary",
      title: "Short Itinerary",
      content: (
       <p>Day 1: Arrival in Haridwar/Rishikesh, take a dip in the Ganges, attend Ganga Aarti in the evening.<br/>

       Day 2: Travel to Joshimath, enjoy the scenic drive/trek.<br/>
       
       Day 3: Proceed to Badrinath, visit Badrinath Temple, explore Mana Village, overnight stay.<br/>
       
       Day 4: Depart to Rudraprayag, visit Rudranath Temple, Chamunda Devi Temple, overnight stay.<br/>
       
       Day 5: Travel to Guptkashi, then Kedarnath, trek or take a helicopter ride, visit Kedarnath Temple, overnight stay.<br/>
       
       Day 6: Descend from Kedarnath, return to Rudraprayag, optional visits or relaxation.<br/>
       
       Day 7: Return to Haridwar/Rishikesh, explore the cities, depart with cherished memories.</p>
      ),
    },
    {
      id: "detailed-itinerary",
      title: "Detailed Itinerary",
      content: (
       <ul>

<li>Day 1: Arrival in Haridwar/Rishikesh</li>
<p>Arrive in Haridwar or Rishikesh, the starting points for the Badrinath-Kedarnath Yatra.
Check into your hotel and take some time to relax after your journey.
Attend the evening Ganga Aarti on the banks of the holy Ganges, a soul-stirring experience.</p><br/>

<li>Day 2:Haridwar/Rishikesh to Guptkashi</li>

<p>After breakfast, drive to Guptkashi, the base for the Kedarnath trek.
En route, stop at Devprayag, the confluence of Alaknanda and Bhagirathi rivers, which form the Ganges.
Continue the scenic drive through Rudraprayag and reach Guptkashi.
Check into your hotel and rest for the night.</p><br/>

<li>Day 3: Guptkashi to Kedarnath</li>

<p>Early morning, drive to Gaurikund, the starting point of the Kedarnath trek.
Begin the challenging 16 km trek to Kedarnath, or opt for a helicopter ride (subject to availability and weather conditions).
Upon reaching Kedarnath, visit the revered Kedarnath Temple, seek blessings, and experience the spiritual ambiance.
Overnight stay in Kedarnath.</p><br/>

<li>Day 4:Kedarnath to Guptkashi</li>
<p>After the spiritual experience at Kedarnath, start your descent back to Gaurikund.
From Gaurikund, return to Guptkashi by road.
On the way, visit the Triyuginarayan Temple, believed to be the place of Lord Shiva and Goddess Parvati's wedding.
Overnight stay in Guptkashi.</p><br/>


<li>Day 5: Guptkashi to Badrinath</li>
<p>After breakfast, proceed to Badrinath, the second major destination of the yatra.
En route, visit the beautiful Chopta, also known as the "Mini Switzerland of India."
Reach Badrinath and check into your hotel. Rest and relax after the journey.</p><br/>


<li>Day 6: Badrinath</li>
<p>This day is dedicated to exploring the holy town of Badrinath and its spiritual offerings.
Visit the revered Badrinath Temple, dedicated to Lord Vishnu, and attend the morning 'Abhishek' ceremony.
Explore Mana Village, the last village before the Indo-Tibetan border, known for its unique culture and breathtaking scenery.
Visit other significant sites like Tapt Kund, Narad Kund, and the Mata Murti Temple.
Overnight stay in Badrinath.</p><br/>

<li>Day 7: Badrinath to Haridwar/Rishikesh</li>
<p>On the last day of the yatra, start your journey back to Haridwar or Rishikesh.
En route, visit the Vishnu Prayag, the confluence of the Alaknanda and Dhauliganga rivers.
Upon reaching Haridwar/Rishikesh, you can explore the cities and their spiritual and cultural heritage.
Depart with a sense of fulfillment and spiritual blessings from the Badrinath-Kedarnath Yatra.</p><br/>













       </ul>
      ),
    },
  ];

  return (
    <>
      <Carousel slides={slides} />

      <div className="trek-details">
        <div className="trek-into">
          <h2>Badrinath-Kedarnath Yatra</h2>
          <p>
            The Badrinath-Kedarnath Yatra is a sacred pilgrimage journey located
            in the majestic state of Uttarakhand, India. It is one of the most
            revered and spiritually significant yatras for Hindus, drawing
            millions of devotees and tourists from across the country and around
            the world each year. The yatra encompasses visits to two ancient and
            revered temples: the Badrinath Temple in Badrinath and the Kedarnath
            Temple in Kedarnath, both dedicated to Lord Shiva and Vishnu. The
            journey typically begins in the town of Haridwar or Rishikesh, where
            pilgrims take a dip in the holy waters of the Ganges to cleanse
            their souls before commencing the yatra. From there, they embark on
            a challenging yet breathtakingly beautiful trek or drive through the
            rugged terrain of the Garhwal Himalayas, passing through picturesque
            landscapes, dense forests, and gushing rivers. The first destination
            is the sacred town of Badrinath, situated on the banks of the
            Alaknanda River. The Badrinath Temple, dating back over a thousand
            years, is the focal point of the yatra. Dedicated to Lord Vishnu,
            the temple features an imposing structure with intricate carvings
            and is believed to be one of the Char Dham, the four holiest shrines
            in Hinduism. Pilgrims seek blessings, perform rituals, and immerse
            themselves in the divine aura of the temple. After Badrinath, the
            yatra takes pilgrims to Kedarnath, the northernmost Jyotirlinga
            among the twelve and another essential site in Hindu mythology. The
            Kedarnath Temple is nestled amidst the snow-capped peaks at a height
            of 3,583 meters above sea level, making it one of the most
            challenging pilgrimage sites to reach. Devotees trek or ride on
            mules through rugged trails to reach the temple, where a massive
            stone lingam symbolizes Lord Shiva. The serene and awe-inspiring
            environment adds to the spiritual significance of the pilgrimage.
            Throughout the yatra, pilgrims not only encounter the captivating
            natural beauty of the Himalayas but also experience a sense of
            devotion and tranquility. The journey is not merely a physical one
            but a deeply spiritual and emotional one, with moments of
            reflection, prayer, and self-discovery. The Badrinath-Kedarnath
            Yatra typically takes place during the summer months when the
            temples are accessible due to the harsh weather conditions during
            the winter. While the pilgrimage demands physical endurance, the
            experience of visiting these sacred shrines and being amidst the
            majestic Himalayas is undoubtedly rewarding, leaving devotees with a
            lasting sense of fulfillment and inner peace.
          </p>
          <div>
            <TripPlanner sections={sections} />
          </div>
        </div>

        <div className="trek-sidebar">
          {slidebar.map((slidebar) => (
            <SlideBarCard
              key={slidebar.title}
              title={slidebar.title}
              para={slidebar.para}
              imageUrl={slidebar.imageUrl}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default BadriKedarYatra;
