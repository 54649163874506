import React from "react";
import "../styles/WhyChooseUs.css";
import { FaCheckCircle } from "react-icons/fa";

const WhyChooseUs = () => {
  return (
    <div className="why-choose-us-container">
      <div className="why-choose-us-content">
        <h2>Why Choose Us?</h2>
        <p>
          Choose us for seamless travel experiences: personalized itineraries,
          competitive prices, trusted accommodations, expert guides, and
          unrivaled customer support. Let us make your journey unforgettable.
        </p>
        <br />

        <ul>
          <li>
            <FaCheckCircle /> Tailor made Trips
          </li>
          <li>
            <FaCheckCircle /> Save your time and effort
          </li>
          <li>
            <FaCheckCircle /> Best Services and Quality
          </li>
          <li>
            <FaCheckCircle /> Premium Hotels
          </li>
          <li>
            <FaCheckCircle /> Personal Touch
          </li>
          <li>
            <FaCheckCircle /> Local Expertise
          </li>
        </ul>
      </div>
      <div className="why-choose-us-image">
        <img
        src="https://img.freepik.com/free-photo/wooden-travel-background-with-globe-notepad-coffee-cup-top-view_169016-35186.jpg?w=1380&t=st=1690664372~exp=1690664972~hmac=c29e157af3f5b598762d52d2f70c71cf8ac63591a33565fdddc30006888e97fc"
          alt="Why Choose Us"
        />
      </div>
    </div>
  );
};

export default WhyChooseUs;
