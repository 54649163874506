import React from "react";
import BlogComponent from "./BlogComponent";

const BlogSacredSites = () => {
  const sampleBlog = {
    title: `A Spiritual Sojourn: Visiting the Sacred Sites of Uttarakhand`,
    content: (
      <p>
        A Spiritual Sojourn: Visiting the Sacred Sites of
        Uttarakhand Uttarakhand is a state in northern India that is home to
        some of the most sacred sites in Hinduism. The state is also known for
        its stunning natural beauty, making it a popular destination for
        spiritual seekers and nature lovers alike.
        <br />
        Here are some of the most popular sacred sites to visit in Uttarakhand:
        <br/>Kedarnath Temple: This temple is dedicated to Lord Shiva and is one of
        the twelve Jyotirlingas, or "pillars of light." The temple is located in
        the Garhwal Himalayas at an altitude of 3,583 meters (11,768 feet). The
        journey to Kedarnath is challenging but rewarding, and the views from
        the temple are simply breathtaking. Kedarnath Temple in Uttarakhand
        Kedarnath Temple in Uttarakhand
        <br/> Gangotri Temple: This temple is
        dedicated to the goddess Ganga and is the source of the Ganges River.
        The temple is located in the Garhwal Himalayas at an altitude of 3,100
        meters (10,200 feet). The Gangotri Temple is a popular pilgrimage
        destination for Hindus, and it is said that bathing in the waters of the
        Ganges at this temple can cleanse one of all sins. Gangotri Temple in
        UttarakhandOpens in a new window www.tourmyindia.com Gangotri Temple in
        Uttarakhand 
        <br/>Yamunotri Temple: This temple is dedicated to the goddess
        Yamuna and is the source of the Yamuna River. The temple is located in
        the Garhwal Himalayas at an altitude of 3,293 meters (10,807 feet). The
        Yamunotri Temple is a popular pilgrimage destination for Hindus, and it
        is said that bathing in the waters of the Yamuna at this temple can
        cleanse one of all sins. Yamunotri Temple in UttarakhandOpens in a new
        window en.wikipedia.org Yamunotri Temple in Uttarakhand
        <br/> Badrinath
        Temple: This temple is dedicated to Lord Vishnu and is one of the four
        Char Dhams, or "abodes of the gods." The temple is located in the
        Garhwal Himalayas at an altitude of 3,133 meters (10,280 feet). The
        Badrinath Temple is a popular pilgrimage destination for Hindus, and it
        is said that spending a night at this temple can grant one moksha, or
        liberation from the cycle of rebirth. Badrinath Temple in
        UttarakhandOpens in a new window en.wikipedia.org Badrinath Temple in
        Uttarakhand
        <br/> Haridwar: This city is one of the seven holiest cities in
        India and is located at the confluence of the Ganges and Yamuna rivers.
        Haridwar is a popular destination for pilgrims and tourists alike, and
        it is said that bathing in the waters of the Ganges at this confluence
        can cleanse one of all sins. Haridwar in UttarakhandOpens in a new
        window www.tourmyindia.com Haridwar in Uttarakhand
        <br/> Rishikesh: This city
        is known as the "Yoga Capital of the World" and is a popular destination
        for spiritual seekers and yogis. Rishikesh is located on the banks of
        the Ganges River and is said to be a place where one can find peace and
        tranquility. Rishikesh in UttarakhandOpens in a new window
        en.wikipedia.org Rishikesh in Uttarakhand
        <br/> These are just a few of the
        many sacred sites to visit in Uttarakhand. A spiritual sojourn to this
        beautiful state is sure to be a life-changing experience. Here are some
        tips for planning your spiritual sojourn to Uttarakhand: Start planning
        your trip well in advance.
        <br/> The best time to visit Uttarakhand is during
        the spring (March-April) or autumn (September-October). The weather is
        mild during these months and there are fewer crowds. Be in good physical
        condition. The journey to some of the sacred sites in Uttarakhand can be
        challenging, so it is important to be in good physical condition. Hire a
        guide. A guide can help you plan your itinerary, navigate the trails,
        and carry your luggage. Be respectful of the local culture. 
        <br/>Uttarakhand
        is a Hindu-majority state, so it is important to be respectful of the
        local culture. This includes dressing modestly and avoiding alcohol and
        non-vegetarian food. Pack light. You will only need the essentials for
        your journey. This includes comfortable walking shoes, warm clothing,
        sunscreen, sunglasses, and a hat. Bring a first-aid kit. You may need it
        in case of an emergency. Be prepared for the weather. The weather in the
        Himalayas can change quickly, so be prepared for anything. I hope this
        blog post has inspired you to plan a spiritual sojourn to Uttarakhand.
        It is a truly amazing place that is sure to change your life.
      </p>
    ),
    date: "June 20, 2023",
    imageUrl:
      "https://images.unsplash.com/photo-1642229103178-11e23542bd76?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1171&q=80",
  };

  return (
    <div className="blog-page">
      <BlogComponent
        title={sampleBlog.title}
        content={sampleBlog.content}
        date={sampleBlog.date}
        imageUrl={sampleBlog.imageUrl}
      />
    </div>
  );
};

export default BlogSacredSites;
