import React from 'react'

import '../styles/Banner.css'

const Banner = () => {
  return (
   <>

<div className="banner-section">
      <div className="overlay">
        <h1>Wherever you want to go, we'll get you there</h1>
        <p>Explore exotic destinations and plan your dream vacation with us!</p>
      </div>
    </div>
 

    


   </>
  )
}

export default Banner