import React from "react";
import Carousel from "./Carousel";
import SlideBarCard from "./SlideBarCard";
import TripPlanner from "./TripPlanner";



import "../styles/AllTrek.css";

const BagniGlacierTrek = () => {
  const slides = [
    {
      id: 1,
      image: 'https://images.unsplash.com/photo-1648531247799-eb2ab3821ad1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1916&q=80',
    },
    {
      id: 2,
      image: 'https://plus.unsplash.com/premium_photo-1673283780222-8a7b3366e0ab?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80',
    },
    {
      id: 3,
      image: 'https://images.unsplash.com/photo-1661062479980-c519a2f14710?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1332&q=80',
    },
  ];

  const slidebar = [
    {
      title: "Accomodation",
      para: "Hotel,Camps",
      imageUrl:'https://cdn-icons-png.flaticon.com/128/489/489870.png'
    },

    {
      title: "Best Season",
      para: "Round The Year",
      imageUrl: "https://cdn-icons-png.flaticon.com/128/4360/4360322.png",
    },
   

    {
      title: "Meals",
      para: "Breakfast,Lunch,Dinner",
      imageUrl: "https://cdn-icons-png.flaticon.com/128/857/857681.png",
    },

    {
      title: "Activities",
      para: "Hiking,Camping",
      imageUrl: "https://cdn-icons-png.flaticon.com/128/2280/2280969.png",
    },





  ];

  const sections = [
    {
      id: 'overview',
      title: 'Overview',
      content: (
     <p>The trek begins at Jumma (2578 m) runs along Bagni Ganga fed by glacial melts of Bagni glacier, offers you an undulating terrain to tread up every day.  From a leisurely walk on a 6 ft wide manmade path, to negotiating on loose moraines and boulders strewn to meet its culmination point. In between these two extremes the trail passes through pine and deodar forest, steep hikes on dead glacial beds and flat walk through the vast alpine meadow of Changbang Base camp. Eventually the trail varies in a dramatic fashion ends at Rishikund Top 4800m.

     Rishikund top is a terrain made out of black shale and quite devoid of vegetation, you find yourself standing nose to nose the mount Saf Minal (6,911m) to left you see the mighty Rishi peak (6,992m) and then towards right you see mount  Kalanka (6931)  and  further  right  mount Changbang (6864 m) this series of mountains make the Northern wall of the Nanda Devi sanctuary.
     
     At this place you get to see all above four mentioned mountains from their foot to head, covered with snow standing high seems liking guarding the holy mount Nanda Devi (7816 m) standing in the back drop.
     
     The glacial course of Bagni, is fed on the left by the ice fall of Trishuli, Herdeol and Rishi and on the right flank by Saf Minal, Kalnka, Changbang and Purbi Dronagiri massifs.
     The body of the glacier is built up with numerous small scenic green and blue deep ice lakes and deposited humps of rock splits, loose slippery sandy particles and formidable crevices, these factors are enough vulnerable to impede your progress on the glacial bed . Despite, unfavorable structural formation there are plenty of good spots to behold the spectacular beauty with sprawling snow fields and proudly erected jagged snow peaks which make the exploration outstanding from others.</p>
      ),
    },
    {
      id: 'short-itinerary',
      title: 'Short Itinerary',
      content: (
        <p>
         Day 1 :
Haridwar / Dehradun – Joshimath 268 drive, 8 hrs stay hotel. <br/>

Day 2 :
Joshimath (1,819 m) – Jumma (2578 mt) - Ruing (2793 m), drive 48 km / 2 hour stay in camp.<br/>

Day 3 :
Ruing (2,793) – Dronagiri Village (3,608 m) trek 8 km, 5 hr camp.<br/>

Day 4 :
Dronagiri Village – Bagni Base Camp, (4,498m m) trek 10 km , 7 hrs camp.<br/>

Day 5 :
Bagni Base camp – Changbang BC (4,611m)- Rishikund(4,800m) and back Sem khark (3,804m) 12 km, 7 hrs, camp.<br/>

Day 6 :
Sem Khark (3,804 m) - Jumma road head – Joshimath, trek back 6 hr, drive 45 km, 2 hr hotel.<br/>

Day 7 :
Joshimath – Haridwar / Dehradun airport and final departure.<br/>
        </p>
      ),
    },
    {
      id: 'detailed-itinerary',
      title: 'Detailed Itinerary',
      content: (
      <ul>

<li>Day 1 :
Haridwar / Dehradun – Joshimath 268 drive, 8 hrs stay hotel.</li>

<p>Morning meet at an appointed spot in Haridwar / Rishikesh / Dehradun, assistance by our tour guide and drive for Joshimath, today the entire drive moves along the beautifully carved valleys of the Ganges and the Alkananda rivers, encountering the 5 sacred Prayags (confluences). We make short stops for lunch and photographs on the way. Arriving at Joshimath, check in at hotel for overnight stay.</p><br/>


<li>Day 2 :
Joshimath (1,819 m) – Jumma (2578 mt) - Ruing (2793 m), drive 48 km / 2 hour stay in camp.</li>

<p>Morning after breakfast drive into Dhauli Ganga valley the origin of the world famed Chipko Movement (an environmental conservation movement, triggered by Late Gaura Devi in Raini village) Reaching at Jumma, an easy trek takes you to Ruing village, a small tribal settlement of some 10 houses. Ruing is lovely flat camping ground encircled by a grove of tall Deodar and pine trees and an old traditional Bhotia tribal village. Once you get settled in your camp and after having lunch, you have a good opportunity to explore the village.</p><br/>

<li>Day 3 :
Ruing (2,793) – Dronagiri Village (3,608 m) trek 8 km, 5 hr camp.</li>

<p>Today’s walk goes through some lovely patches of tall Deodar and pine forest, a few ascent and descent along the Bagni Ganga, offering an inspiring close view of Lampak I (6181m) peak during entire walk. The last part of the day’s hike is difficult, negotiating through bolder and loose sandy terrain but soon a graceful sight of a scenic old village is seen upfront.</p><br/>

<li>Day 4 :
Dronagiri Village – Bagni Base Camp, (4,498m m) trek 10 km , 7 hrs camp.</li>

<p>The trail passes by the side of Dronagiri village for some time, then a steep climb in a zig zag way to the top of village, from where you get a fascinating glimpse of mount Rishi and Bagni George through which the tumultuous Bagni Ganga gushing down to meet the Dhauli Ganga at Jumma. Further the walk progresses on a rough and stark terrain embedded with running glacial water bodies, moraine, loose dead glacier and bony ridges that all begs some tiring effort but always rewarded with awe inspiring mountain beauty with plethora of snow capped peaks and vast glacial zone of Bagni gorge. Later you find a lovely flat piece of ground for camping at the base of Bagni and Garur peak.</p><br/>

<li>Day 5 :
Bagni Base camp – Changbang BC (4,611m)- Rishikund(4,800m) and back Sem khark (3,804m) 12 km, 7 hrs, camp.</li>

<p>Today you are advised to start early with a packed breakfast, since its always worthy to catch the rewarding sight with early light on the peaks and blue glacial lakes. If you got guts, an endeavoring step will bring to explore further deeper into gorge towards foot of Changbang massif the highest one in area. Later trek down to Sem Khark.</p><br/>


<li>Day 6 :
Sem Khark (3,804 m) - Jumma road head – Joshimath, trek back 6 hr, drive 45 km, 2 hr hotel.</li>

<p>Its again the same route down to Jumma Road head via Ruing, where your pre-arranged vehicle will be waiting to drive for Joshimath.</p><br/>


<li>Day 7 :
Joshimath – Haridwar / Dehradun airport and final departure.</li>
<p>Morning take an early wake up and drive for Dehradun Airport. This entire journey will take 9 hours. One can take overnight train from Haridwar Railway Station or an evening flight from Jolly Grant Airport toward home destination or can stay in Rishikesh.</p><br/>




      </ul>
      ),
    },
  ];


  return (
    <>
      <Carousel slides={slides} />

      <div className="trek-details">
        <div className="trek-into">
          <h2>Bagni-Glacier Trek</h2>
          <p>
            Mountains, rivers and trees have always been revered and worshiped
            by the people of Himalaya and have been the core of spiritual faith
            for time immemorial. The legendry peak of Dronagiri, has also been a
            centre of devotion and immense faith for the locals, Still revered
            as a protector and main deity of the village. Dronagiri has been
            depicted in Puranas (manuscripts) for its sacredness and treasure of
            medicinal herbs (Sanjivinee). It has a mythological connection with
            the epic Ramayan, Lord Hanuman picked up Sanjivanee from the
            mountain of Dronagiri and saved Laxman’s life. Bagni Glacier is a
            moderate trek lies in the Northern west part of the world famed
            Nanda Devi sanctuary, in the Dhauli Ganga Valley of Garhwal
            Himalayas. On Bagni Glacier Trek, you are confronted by numerous
            challenges every day, which offers you fascinating close view of
            giant towering mountains like Purbi (eastern) Dronagiri,
            Rishi,Safminal, kalanka and Changbang and many other unnamed peaks
            and at the same point of time a glistening distant view of Lampak
            and Hardeval steals a charm. Dronagiri Village (3615 m) According to
            the Hindu faith, this legendry village procured it name from a great
            warrior, scholar and mentor of Mahabharata Guru Dronacharya, it is
            believed that he meditated here for several years and later attained
            enlightenment and earned fame as a renowned ascetic figure and
            gifted warrior. From the time immorial, Dronagiri has been a shelter
            for the Bhotias of Chamoli Garhwal, aboriginally from nomadic
            lineage and enjoy a prideful past. Before 1962 the people were
            mainly engaged in cross border trade with Tibet, popularly known as
            Silk Route trade,s bringing salt, the most essential ingredient,
            fine silk and pashmina wool, expensive metals such as gold and
            silver, precious gems and stones and selling back food
            grain,jaggery, sugar and clothe. Animals like horses, mules, goats
            and donkeys were the key source of transportation and the trade was
            fully based on barter system. Terminology – Gad = river, Nala,
            Gadera = water stream, Dhar – ridged, Khal – Pass, Khark – Hault,
            Dhura – High Pass. Ling = Cairn (a pile of stones for showing way in
            nature). Malla = Upper and Talla – Lower.
          </p>
          <div>
          <TripPlanner sections={sections} />

          </div>
        </div>

        <div className="trek-sidebar">
          {slidebar.map((slidebar) => (
            <SlideBarCard
              key={slidebar.title}
              title={slidebar.title}
              para={slidebar.para}
              imageUrl={slidebar.imageUrl}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default BagniGlacierTrek;
